import reemplazarCaracter from "./reemplazarCaracter";


/**
 * Convierte un número a formato precio.
 * Ejemplo: 45334.24 a 45.334,24 €
 */
export default (
        numero, 
        anadirMoneda=true
    ) => {

    if (!numero || typeof numero !== "number") return "0 €"
    
    // Ejemplo: 45334.24
    let formatoMiles = new Intl.NumberFormat('en-US').format(numero); // Ejemplo: 4,4541.24
    
    // Cambio puntos por comas, y comas por puntos
    let indexPunto = formatoMiles.indexOf(".");
    
    let todoAPuntos = formatoMiles.replace(/,/g, ".");
    
    
    let numeroFormateado = reemplazarCaracter(todoAPuntos, ',', indexPunto);
    
    
    if (anadirMoneda) {
        numeroFormateado = numeroFormateado + " €";
    }
    
    return numeroFormateado
}


