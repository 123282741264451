import {
  UPDATE_CONTRATO_INFO,
  UPDATE_CONTRATOS_LIST,
  UPDATE_MODALES_CONTRATOS,
  UPDATE_BUSQUEDA_CONTRATOS,
  UPDATE_CHECKERS_CONTRATOS,
} from '../constants/contratos.action-types'

export function updateContratoInfo(contrato) {
  return { type: UPDATE_CONTRATO_INFO, contrato }
};

export function updateListaContratos(listado) {
  return { type: UPDATE_CONTRATOS_LIST, listado}
}

export function updateModalesContratos(modales) {
  return { type: UPDATE_MODALES_CONTRATOS, modales}
}

export function updateCheckersContratos(checkers) {
  return { type: UPDATE_CHECKERS_CONTRATOS, checkers}
}

export function updateBusquedaContratos(busqueda) {
  return { type: UPDATE_BUSQUEDA_CONTRATOS, busqueda}
}

