import axios_creator from 'axios';
import config from '../config.js'

function createAxios() {
  const axios = axios_creator.create({
    baseURL: `${config.apiConfig.URL}/api/v1/`,
    timeout: 20000,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
    }
})
  return axios
}

export const createCliente = async (body) => {
    const axios = createAxios()
    return axios.post('/user/', body)
}

export const createPhone = async(body) => {
  const axios = createAxios()
  return axios.post('/user/phone/', body)
}

export const createMail = async(body) => {
  const axios = createAxios()
  return axios.post('/user/email/', body)
}

export const createAddress = async(body) => {
  const axios = createAxios()
  return axios.post('/user/address/', body)
}

export const updateClienteAxios = async(id, body) => {
  const axios = createAxios()
  return axios.patch(`/comercial/cliente/${id}/`, body)
}

export const updateUserAxios = async(id, body) => {
  const axios = createAxios()
  return axios.patch(`/user/${id}/`, body)
}

export const getClientesByAgente = async() => {
  const axios = createAxios()
  return axios.get('/comercial/cliente/');
}

export const getClienteById = async (id) => {
  const axios = createAxios()
  return axios.get(`/comercial/cliente/${id}/`)
}

export const busquedaClientesAgente = async(params) => {
  const axios = createAxios()
  return axios.get(`/comercial/cliente/`, {params})
}

export const enviarRecuperarPassword = async (body) => {
  const axios = createAxios()
  return axios.post('/user/password_recovery/', body)
}