import React from "react";
import Alert from 'react-bootstrap/Alert';


/**
 * @typedef {Object} Props
 * @property {boolean} mostrar Determina si se debe mostrar la alerta.
 * @property {function} setMostrar Cambia el estado de mostrar la alerta.
 * @property {string} texto Cadena de texto que se muestra dentro de la alerta.
*/

/**
 * Crea una alerta con fondo rojo que se puede cerrar
 * @param {Props} props
*/
export default ({
    mostrar = false, 
    setMostrar= () => {},
    texto = "No se ha podido descargar el documento."
  }) => {
  
    if (mostrar) {
      return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
          <Alert variant="danger" onClose={() => setMostrar(false)} dismissible>
            <Alert.Heading>Aviso</Alert.Heading>
            <p>
              {texto}
            </p>
          </Alert>
        </div>
      );
    } else {
      return 
    }
  }