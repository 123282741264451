import React from 'react';
import moment from 'moment';
import { FaTimes, FaHouseDamage, FaBolt, FaHotjar, FaQuestion } from "react-icons/fa";

export const handleFechaBool = (fecha) => {
    if (!fecha) {
      return (
        <FaTimes style={{ color: 'red' }} />
      );
    }

    return (
      moment(fecha).format('YYYY-MM-DD')
    );
  }

export const numberFormatter = (numero = 0, decimales = 2) => {
  if (numero === undefined || numero === null) numero = 0;

  const numeroString = numero.toString();
  let resultado = '';
  if (numeroString.includes('.')) {
    const numeroSplit = numeroString.split('.');
    if (numeroSplit[1].length >= decimales) {
      resultado = `${numeroSplit[0]},${numeroSplit[1].substring(0, decimales)}`;
    } else {
      const decimalesFaltantes = Math.abs(numeroSplit[1].length - decimales);
      resultado = `${numeroSplit[0]},${numeroSplit[1]}`;
      for (let i = 0; i < decimalesFaltantes; i += 1) {
        resultado += '0';
      }
    }
  } else {
    resultado = `${numeroString},`;
    for (let i = 0; i < decimales; i += 1) {
      resultado += '0';
    }
  }

  return resultado;
};

export const puntoPorComa = (numero = 0) => {
  if (numero === undefined || numero === null) numero = 0;
  return numero.toString().replace('.', ',');
};

export const checkNegocio = (negocio, modelo) => {
  if (modelo === 'SER') {
    return <FaHouseDamage color={"#28a745"} />
  }

  if (negocio === 1) {
    return <FaBolt color={"#ffc107"} />
  }

  if (negocio === 2) {
    return <FaHotjar color={"#dc3545"} />
  }

  return <FaQuestion />
}

