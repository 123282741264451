import axios_creator from 'axios';
import config from '../config.js'

export function createAxios(servicio) {
  let url = config.apiConfig.URL
  if(servicio === 'FACTURACION') url = config.apiConfig.URLFACTURACION

  const axios = axios_creator.create({
    baseURL: `${url}/api/v1/`,
    timeout: 20000,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
    }
})
  return axios
}

function getGraphQLQuery () {
  const axios = axios_creator.create({
    baseURL: `${config.apiConfig.URLGRAPHQL}/`,
    timeout: 20000
  });
  return axios;
}

export const get_query = async(params) => {
  const axios = getGraphQLQuery()
  if(!params.query) return Promise.reject()
  
  return axios.post('', params)
}
