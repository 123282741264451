import { UPDATE_CLIENTE_ROOT, UPDATE_LOGIN_ROOT } from "../constants/root.action-types"


const initialState = {
  cliente: {},
  login: {
    loggedIn: false,
    loaded: false
  }
}

function rootReducer(state=initialState, action) {
  switch(action.type){
    case UPDATE_CLIENTE_ROOT:
      return Object.assign({}, state, {
          cliente : action.cliente
        })
    case UPDATE_LOGIN_ROOT:
      return Object.assign({}, state, {
          login : action.login
        })
    default:
      return state;
  }
}

export default rootReducer;