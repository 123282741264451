/**
 * Devuelve la dirección completa en formato string
 */
export default (
    address // Objeto dirección del modelo accounts_userdireccion
) => {
    var full_address = '';
    var address_complement = '';
    if (address) {
        full_address = `${address.tipo_via} ${address.nombre_via}, ${address.numero_via}`;
        if (address.bloque) address_complement += `BQ. ${address.bloque} `;
        if (address.portal) address_complement += `PORT. ${address.portal} `;
        if (address.escalera) address_complement += `ESC. ${address.escalera} `;
        if (address.piso) address_complement += `PS. ${address.piso} `;
        if (address.puerta) address_complement += `PTA. ${address.puerta} `;
    }
    if (address_complement) full_address = `${full_address} - ${address_complement}`;
    return full_address;
};
