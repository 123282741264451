/* eslint-disable react/prop-types */
import React from 'react';
import Pagination from 'react-bootstrap/Pagination';


/** elementos, paginaActual, limit
 * Requeridos:
 * @param {Integer} elementos - Los elementos totales
 * @param {Object} busqueda - Objeto que tiene los params de busqueda
 * @param {Integer} paginaActual - nombre de la cabecera
 * @param {Integer} limit - limite de elementos por página
 * @param dispatch - dispatcher para usar
 * @param updateBusqueda - funcion que se usa para actualizar la busqueda
 * @param updateLista - funcion que se usa para actualizar la lista
 */

class PaginacionComponente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  changePagina(pagina) {
    const { elementos, updateLista, updateBusqueda, limit, busqueda, funcionCargando } = this.props;
    if (pagina > parseInt(elementos / limit, 10) || pagina < 0) return 0;

    const paginaActual = pagina || 0;
    const newBusqueda = {
      ...busqueda,
      offset: paginaActual * limit,
      paginaActual,
    };

    updateBusqueda(this.props.dispatch, newBusqueda, this.props.checkers);
    updateLista(this.props.dispatch, newBusqueda, this.props.checkers, funcionCargando);
    return 0;
  }

  formaPagination() {
    const { props } = this;
    const { elementos, limit, paginaActual } = props;
    const pages = parseInt(elementos / limit, 10);
    const currentPage = paginaActual - 1;
    const pagination = [];
    let paginasMostrar = 10
    if(window.innerWidth <= 576) {
      paginasMostrar = 5
    }
    if (pages < 1) {
      return <Pagination.Item onClick={() => this.changePagina(1)}>{1}</Pagination.Item>;
    }

    if (pages <= paginasMostrar && pages >= 1) {
      for (let i = 0; i <= pages; i += 1) {
        pagination.push(
          <Pagination.Item
            active={i === currentPage + 1}
            onClick={() => this.changePagina(i)}
          >
            {i + 1}
          </Pagination.Item>,
        );
      }
      return pagination;
    }

    const izquierda = (currentPage - paginasMostrar) > 0;
    const derecha = (currentPage + paginasMostrar) < pages;
    if (izquierda && derecha) {
      for (let i = 0; i < 10; i += 1) {
        if (i < paginasMostrar) {
          pagination.push(
            <Pagination.Item
              onClick={() => this.changePagina(currentPage - (paginasMostrar - i) + 1)}
            >
              {currentPage - (paginasMostrar - i)}
            </Pagination.Item>,
          );
        }

        if (i >= paginasMostrar) {
          pagination.push(
            <Pagination.Item
              active={(currentPage + (i - paginasMostrar)) === currentPage}
              onClick={() => this.changePagina(currentPage + (i - paginasMostrar) + 1)}
            >
              {currentPage + (i - paginasMostrar)}
            </Pagination.Item>,
          );
        }
      }
    }

    if (!izquierda) {
      for (let i = 0; i < paginasMostrar; i += 1) {
        if (i < currentPage) pagination.push(<Pagination.Item onClick={() => this.changePagina(i)}>{i + 1}</Pagination.Item>);

        if (i >= currentPage) {
          pagination.push(
            <Pagination.Item
              active={currentPage + (i - currentPage) - 1 === currentPage}
              onClick={() => this.changePagina(currentPage + (i - currentPage))}
            >
              {currentPage + (i - currentPage) + 1}
            </Pagination.Item>,
          );
        }
      }
    }

    if (!derecha) {
      const totalDerecha = pages - currentPage;
      for (let i = paginasMostrar; i > 0; i -= 1) {
        if (i < totalDerecha) {
          pagination.push(
            <Pagination.Item
              active={(currentPage - i + totalDerecha) === (currentPage)}
              onClick={() => this.changePagina(currentPage - i + totalDerecha + 1)}
            >
              {currentPage - i + totalDerecha}
            </Pagination.Item>,
          );
        }

        if ((i) >= totalDerecha) {
          pagination.push(
            <Pagination.Item
              active={(currentPage - i + totalDerecha) === currentPage}
              onClick={() => this.changePagina(currentPage - i + totalDerecha + 1)}
            >
              {currentPage - i + totalDerecha}
            </Pagination.Item>,
          );
        }
      }
    }

    return pagination;
  }

  render() {
    const { props } = this;
    const { elementos, paginaActual, limit } = props;
    return (
      <Pagination className={this.props.className ? this.props.className : "float-right"}>
        <Pagination.First onClick={() => this.changePagina(0)} />
        <Pagination.Prev onClick={() => this.changePagina(paginaActual - 1)} />
        {this.formaPagination()}
        <Pagination.Next onClick={() => this.changePagina(paginaActual + 1)} />
        <Pagination.Last onClick={() => this.changePagina(parseInt(elementos / limit, 10))} />
      </Pagination>
    );
  }
}

export default (PaginacionComponente);
