
import {
  UPDATE_CONTRATO_INFO,
  UPDATE_CONTRATOS_LIST,
  UPDATE_MODALES_CONTRATOS,
  UPDATE_BUSQUEDA_CONTRATOS,
  UPDATE_CHECKERS_CONTRATOS,
} from '../constants/contratos.action-types';

export const initialState = {
  listado: {
    count: 0,
    results: []
  },
  contrato: {},
  modales: {},
  busqueda: {},
  checkers: {}
}

function contratacionReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONTRATO_INFO:
      return Object.assign({}, state, {
        contrato: action.contrato
      })
    case UPDATE_CONTRATOS_LIST:
      return Object.assign({}, state, {
        listado: action.listado
      })
    case UPDATE_MODALES_CONTRATOS:
      return Object.assign({}, state, {
        modales: action.modales
      })
    case UPDATE_BUSQUEDA_CONTRATOS:
      return Object.assign({}, state, {
        busqueda: action.busqueda
      })
    case UPDATE_CHECKERS_CONTRATOS:
      return Object.assign({}, state, {
        checkers: action.checkers,
      })
    default:
      return state;
  }
}

export default contratacionReducer;