import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Header from '../components/header/Header'
import Login from './login/LoginApp'
import { checkToken } from '../_services/axiosUser'
import { updateLogin, updateCliente } from '../redux/actions/root.actions'
import { ListaFacturas } from './listaFacturas/ListaFacturas'
import { ListadoContratos } from './contrato/ListadoContratos'
import { Dashboard } from './dashboard/Dashboard'
import { RecuperarPassword } from './recuperarPassword/RecuperarPassword'
import Landing from './landing/Landing'

const check = (login, dispatch, setLoaded, busqueda) => {
  checkToken()
    .then(response => {
      if (!response.data.cliente || !response.data.cliente.id) {
        dispatch(updateLogin({ ...login, loggedIn: false, loaded: true }))
      } else {
        dispatch(updateLogin({ ...login, loggedIn: true, loaded: true }))
      }

      dispatch(updateCliente({ ...response.data }))
      setLoaded(true)
    })
    .catch(e => {
      dispatch(updateLogin({ ...login, loggedIn: false, loaded: true }))
      setLoaded(true)
    })
}

export const RouterViews = () => {
  const login = useSelector(state => state.root.login)
  const [loaded, setLoaded] = useState(login.loggedIn)
  const [loggedIn, setLoggedIn] = useState(login.loaded)
  const dispatch = useDispatch()

  useEffect(() => {
    setLoggedIn(login.loggedIn)
  }, [login])
  useEffect(() => {
    check(login, dispatch, setLoaded)
  }, [])
  return (
    <div style={{ visibility: loaded ? 'visible' : 'hidden' }}>
      <Router>
        {loggedIn ? <Header /> : ''}
        <div className='container-fluid'>
          <Route
            exact
            path='/'
            render={() =>
              !loggedIn ? <Landing /> : <Redirect to='/dashboard' />
            }
          />
          <Route
            exact
            path='/dashboard'
            render={() =>
              !loggedIn ? <Redirect to='/login' /> : <Dashboard />
            }
          />
          <Route
            exact
            path='/facturas'
            render={() =>
              !loggedIn ? <Redirect to='/login' /> : <ListaFacturas />
            }
          />
          <Route exact path='/forgot' render={() => <RecuperarPassword />} />
          <Route
            exact
            path='/login'
            render={() =>
              !loggedIn ? <Login /> : <Redirect to='/dashboard' />
            }
          />
          <Route
            exact
            path='/contratos'
            render={() => (!loggedIn ? <Login /> : <ListadoContratos />)}
          />
        </div>
      </Router>
    </div>
  )
}

export default RouterViews
