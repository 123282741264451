import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import Api from '../../utils/Api.js'
import { EventEmmiter } from '../../utils/eventEmitter.js';
import { FaUser, FaSignOutAlt } from 'react-icons/fa'
import SidebarButton from '../sidebar/Sidebar.js'
import './Header.scss';
import { connect } from 'react-redux';
import { updateLogin } from '../../redux/actions/root.actions.js';
import LogoRojo from "../../assets/img/logo_umeme.png";

function mapStateToProps(state) {
	return ({
		cliente: state.root.cliente
	})
}

class Header extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			username: "",
			desk: true
		}

		EventEmmiter.subscribe('user', (event) => this.setUser(this.props.cliente))

		this.setUser = this.setUser.bind(this);
		this.logOut = this.logOut.bind(this);
	}

	setUser(user) {
		this.setState({
			username: ` ${user.nombre} ${user.apellido1}`
		})
	}

	logOut() {
		let api = new Api();
		api.delete_token();
		this.props.dispatch(updateLogin({ loggedIn: false }))
	}

	render() {
		return (
			<nav className="navbar navbar-expand-lg">
				<div className="container-fluid">
					<SidebarButton />

					<div style={{display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
						<Link to="/dashboard" className="contenedorLogoRojo">
							<img
								src={LogoRojo}
								alt="Loading"
								style={{maxHeight: '25px', width: 'auto', display: 'flex'}}
							/>
						</Link>
					</div>
					
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="nav navbar-nav nav-flex-icons ml-auto">
							<li className="nav-item dropdown">
								<Dropdown className="d-inline-block ml-auto">
									<Dropdown.Toggle direction='left' id="navbarDropdownMenuLink" as="span">
										<Link className="nav-link">
											<FaUser /> {` ${this.props.cliente.nombre} ${this.props.cliente.apellido1}`}
										</Link>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
										<Dropdown.Item><Link className="dropdown-item" to='/login' onClick={this.logOut}><FaSignOutAlt /> Salir</Link></Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default connect(mapStateToProps)(Header);
