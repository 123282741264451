import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import PaginacionComponente from "../../components/common/paginacion/PaginacionComponente";
import { handleSorts, sortBy, propsUpdater } from "../utils/handlers";
import {
  updateBusquedaContratos,
  updateCheckersContratos,
  updateListaContratos,
} from "../../redux/actions/contratos.actions";
import { getContratosCliente } from "../../_services/axiosContrato";
import {
  handleError,
  handleDireccionCompletaContrato,
  checkEstadoContrato,
  query_listado_contratos,
} from "../utils/utils";
import { checkNegocio } from "../utils/common/formatters";
import config from "../../config";
import { FaFilePdf, FaQuestion } from "react-icons/fa";
import FiltroComponente from "../../components/common/filtro/FiltroComponente";

import dataFiltro from "./dataFiltro.json";
import { PantallaCarga } from "../../components/common/pantallaCarga/PantallaCarga";
import { downloadFile } from "../utils/common/downloader";
import SinResultados from "../../components/common/sinResultados/SinResultados";
import { MensajesError } from "../../components/common/mensajesError/MensajesError";
import formatoFecha from "../../utils/formatoFecha";
import { get_query } from "../../_services/utils";


import {FilaPaginacion} from "../../components/common/FilaPaginacion/FilaPaginacion";
import AlertaError from "../../components/AlertaError/AlertaError";

const updateBusqueda = (dispatch, busqueda) => {
  dispatch(updateBusquedaContratos(busqueda));
};

const updateLista = (dispatch, busqueda, checkers, funcionCargando) => {
  const newBusqueda = {
    ...busqueda,
  };
  retrieveListadoContratos(dispatch, newBusqueda, checkers, funcionCargando);
};

const acciones = (documento, filename, setMostrarAlerta) => {
  const path = `/api/v1/documentos/${documento ? documento : ""}`;
  
  return (
    <a
      onClick={ async () => {
        let resultado = await downloadFile(
          config.apiConfig.UR,
          path,
          filename
        );

        if (!resultado) setMostrarAlerta(true)
      }}
    >
      <Button disabled={!documento} size="sm" color={"danger"}>
        <FaFilePdf />
      </Button>
    </a>
  );
};

const createTable = (listado = [], setMostrarAlerta) => {
  const tabla = [];
  for (let i = 0; i < listado.length; i++) {
    const contrato = listado[i];
    tabla.push(
      <tr>
        <th scope="row">
          {contrato.producto ? (
            checkNegocio(contrato.producto.negocio, contrato.modelo_contrato)
          ) : (
            <FaQuestion />
          )}
        </th>
        <td className="d-none d-sm-table-cell">{contrato.num_contrato}</td>
        <td>{checkEstadoContrato(contrato.estado)}</td>
        <td className="d-none d-sm-table-cell">
          {contrato.producto ? contrato.producto.CUPS : null}
        </td>
        <td>{handleDireccionCompletaContrato(contrato)}</td>
        <td className="d-none d-sm-table-cell">
          {formatoFecha(contrato.fecha_inicio)}
        </td>
        <td className="d-none d-sm-table-cell">
          {formatoFecha(contrato.fecha_fin)}
        </td>
        <td>
          {acciones(
            contrato.contrato_archivo_id,
            `${contrato.num_contrato}.pdf`,
            setMostrarAlerta
          )}
        </td>
      </tr>
    );
  }
  return tabla;
};

const retrieveListadoContratos = (
  dispatch,
  busqueda,
  checkers,
  funcionCargando
) => {
  let variables = { nif: busqueda.nif };
  funcionCargando(true);
  const newBusqueda = {
    query: query_listado_contratos,
    variables: variables,
  };
  get_query(newBusqueda)
    .then((response) => {
      funcionCargando(false);
      let contratos = {
        results: response.data.data.comercial_contrato,
      };
      dispatch(updateListaContratos(contratos));
    })
    .catch((error) => {
      handleError(dispatch, updateCheckersContratos, checkers, "errorListado");
      funcionCargando(false);
    });
};

export const ListadoContratos = () => {
  const [cargando, setCargando] = useState(false);
  const contratos = useSelector((state) => state.contratos.listado);
  const cliente = useSelector((state) => state.root.cliente);
  const busqueda = useSelector((state) => state.contratos.busqueda);
  const checkers = useSelector((state) => state.contratos.checkers);
  const dispatch = useDispatch();
  const [toggleFiltro, setToggleFiltro] = useState(false);

  const [limit, setLimit] = useState(10);
  const [desdeContrato, setDesdeContrato] = useState(0);
  const [hastaContrato, setHastaContrato] = useState(limit);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);

  const cambiarPagina = (botonSeleccionado) => {
    let desdeActual = desdeContrato;
    let hastaActual = hastaContrato;

    switch (botonSeleccionado) {
      case "<":
        if (desdeActual == 0) return;

        setDesdeContrato(desdeActual - limit);
        setHastaContrato(hastaActual - limit);
        break;

      case ">":
        if (desdeActual + limit > contratos?.results?.length) return

        setDesdeContrato(desdeActual + limit);
        setHastaContrato(desdeActual + limit + limit);
        break;

      default:
        setDesdeContrato(0);
        setHastaContrato(0 + limit);
    }
  };

  useEffect(() => {
    cambiarPagina();
  }, [limit]);

  useEffect(() => {
    if (cliente.id) {
      updateBusqueda(dispatch, {
        ...busqueda,
        cliente: cliente.cliente.id,
        nif: cliente.nif,
      });
    }
  }, [cliente]);

  useEffect(() => {
    if (busqueda.cliente) {
      retrieveListadoContratos(dispatch, busqueda, checkers, setCargando);
    }
  }, [busqueda]);

  return (
    <>
      <div className="col-md-12 row px-5 py-2">
        <div className="col-md-6">
          <h4>Contratos</h4>
        </div>
        <div className="col-md-2" />
        <div className="float-right pl-2 col-md-4">
          <MensajesError checkers={checkers} />
        </div>
      </div>
      <div className="col-md-12">
        <div className="row my-2">
          {/* <div className="col-md-8">
            <Button
              color="danger"
              onClick={() => setToggleFiltro(!toggleFiltro)}
            >
              Filtros
            </Button>
          </div> */}
          {/* <div className="col-md-4">
            <input
              className="form-control fright"
              type="text"
              placeholder="Buscar"
              aria-label="Buscar"
              onChange={(e) =>
                updateBusqueda(dispatch, {
                  ...busqueda,
                  search: e.target.value,
                })
              }
              value={busqueda.search}
            />
          </div> */}
        </div>
        <div className="col-md-12 my-2 row">
          <FiltroComponente
            data={dataFiltro.data}
            isOpen={toggleFiltro}
            busqueda={busqueda}
            checkers={checkers}
            prop={busqueda}
            updateBusqueda={updateBusqueda}
            updateLista={updateLista}
            dispatch={dispatch}
            funcionCargando={setCargando}
          />
        </div>
        {cargando ? (
          <PantallaCarga />
        ) : !contratos.results || !contratos.results.length ? (
          <SinResultados />
        ) : (
          <Table
            size="sm"
            hover
            striped
            responsive
            style={{ textAlign: "center" }}
            className="justify-content-center"
          >
            <thead>
              <tr>
                <th scope="col" 
                // onClick={() => sortBy("negocio")}
                >
                  <Button size="sm" color="white">
                    <div className="row">
                      Tipo
                      {/* {handleSorts(busqueda, "negocio")} */}
                    </div>
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell"
                  // onClick={() => sortBy("num_contrato")}
                >
                  <Button size="sm" color="white">
                    <div className="row">
                      <span>Número contrato</span>
                      {/* {handleSorts(busqueda, "negocio")} */}
                    </div>
                  </Button>
                </th>
                <th scope="col" 
                  // onClick={() => sortBy("estado")}
                >
                  <Button size="sm" color="white">
                    <div className="row">
                      Estado
                      {/* {handleSorts(busqueda, "estado")} */}
                    </div>
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell"
                  // onClick={() => sortBy("producto")}
                >
                  <Button size="sm" color="white">
                    <div className="row">
                      Punto de suministro
                      {/* {handleSorts(busqueda, "producto")} */}
                    </div>
                  </Button>
                </th>
                <th scope="col">
                  <Button size="sm" color="white">
                    Dirección
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell"
                  // onClick={(e) => sortBy("fecha_inicio")}
                >
                  <Button size="sm" color="white">
                    <div className="row">
                      Inicio contrato
                      {/* {handleSorts(busqueda, "fecha_inicio")} */}
                    </div>
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell"
                  // onClick={(e) => sortBy("fecha_fin")}
                >
                  <Button size="sm" color="white">
                    <div className="row">
                      Final contrato
                      {/* {handleSorts(busqueda, "fecha_fin")} */}
                    </div>
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell"
                  // onClick={(e) => sortBy("fecha_fin")}
                >
                  <Button size="sm" color="white">
                    <div className="row">
                      Acciones
                      {/* {handleSorts(busqueda, "fecha_fin")} */}
                    </div>
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {createTable(
                contratos.results?.slice(desdeContrato, hastaContrato),
                setMostrarAlerta
              )}
            </tbody>
          </Table>
        )}

        {contratos?.results?.length > 10 && (

          <FilaPaginacion 
            totalResultados={contratos?.results?.length}
            functionPulsaBoton={cambiarPagina}
            limiteDeElementos={limit}
            setLimiteDeElementos={setLimit}
            deshabilitadoBotonHaciaAtras={desdeContrato === 0}
            deshabilitadoBotonHaciaDelante={hastaContrato > contratos?.results?.length}
          />
         
        )}
      </div>

      { mostrarAlerta && <AlertaError mostrar={mostrarAlerta} setMostrar={setMostrarAlerta} /> }

      <div style={{ height: "200px" }}></div>
    </>
  );
};
