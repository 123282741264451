import React from 'react'
import { Alert } from "reactstrap"
import { FaTimesCircle } from "react-icons/fa"

const mostrarError = (checkers) => {
  const errores = checkers.checkers;
  let mensajeError = 'Error desconocido'
  let existeError = false
  switch (true) {
    case errores.errorListado:
      mensajeError = "Error recuperando listado, por favor recargue la página"
      existeError = true
      break;
    default:
      existeError = false
  }



  return (
    <div style={{ visibility: !existeError ? 'hidden' : "visible" }}>
      <Alert color='danger'>
        <FaTimesCircle /> {mensajeError}
    </Alert>
    </div>)
}

export const MensajesError = (checkers = {}) => {
  return (
    <div className='col-md-12'>
      {mostrarError(checkers)}
    </div>
  )
}