import axios_creator from 'axios';
import config from '../config.js'

function createAxios() {
  const axios = axios_creator.create({
    baseURL: `${config.apiConfig.URL}/api/v1/`,
    timeout: 20000,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
    }
})
  return axios
}


export const getContratosCliente = async(params) => {
  const axios = createAxios()
  if(!params.cliente) return Promise.reject()
  
  return axios.get('comercial/contratacion/', {params})
}
