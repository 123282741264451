import React from 'react'

import { FaExclamationTriangle } from 'react-icons/fa'

export const SinResultados = () => {

    return(
        <div className='justify-content-center' style={{textAlign: "center"}}>
            <FaExclamationTriangle /> No existen resultados
        </div>
    )
}

export default SinResultados