import dayjs from 'dayjs';

/**
 * Devuelve la fecha en formato DD/MM/YYYY
 */
export default (
    fecha, // en formato año-mes-dia 
) => {

    if (!fecha) return ""

    return dayjs(fecha).format("DD/MM/YYYY")
}