import React from 'react';
import { FaListAlt, FaUser } from 'react-icons/fa';


export default [
	{
		ruta: "/dashboard",
		icono: <FaUser />,
		titulo: "Panel principal"
	},
	{
		ruta: "/facturas",
		icono: <FaListAlt />,
		titulo: "Facturas"
	},
	{
		ruta: "/contratos",
		icono: <FaListAlt />,
		titulo: "Contratos"
	},
]