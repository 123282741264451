/**
 * Reemplaza un carácter de un string con el índice del carácter que se quiere reemplazar
 */
export default (origString, replaceChar, index) => {

    if (!origString) return
    if (!replaceChar) return
    if (!index) return

    let firstPart = origString.substr(0, index);
    let lastPart = origString.substr(index + 1);
      
    let newString = firstPart + replaceChar + lastPart;
    return newString;
}