import Api from '../utils/Api.js';
import { axiosCreate } from '../utils/Api'

class User {

    constructor(user){
        this.user = {
            id: localStorage.getItem('user_id'),
            nif: "",
            nombre: "",
            apellido1: "",
            apellido2: "",
            username: "",
            agente: {
               id: 0,
               administrador: false,
               direccion: 0,
               email: 0,
               telefono: 0,
               proveedor: 0 
            }
        }

        if(user){
            this.user = user;
            localStorage.setItem('user_id', this.user.id);
        }else{
            this.retrieve();
        }
    }

    get_user(){
        return this.user;
    }

    retrieve(){
        const axios = axiosCreate();
        axios.get('/user/profile/?agente=1').then(data => {
                this.user = data[0];
                localStorage.setItem('user_id', this.user.id);
            }
        ).catch(e => {
        });
    }

    save(){
        let call = new Api("agente"); 
        call.make_call("POST", this.agente)
        .then(data => {
            this.agente = data;
        });
    }

    update(){
        let call = new Api("agente", this.agente.id); 
        call.make_call("PATCH", this.agente)
        .then(data => {
            this.agente = data;
        });
    }

}

export default User