import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaAlignJustify, FaArrowLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import Logo from '../../assets/img/logo_blanco.png';
import { updateLogin } from '../../redux/actions/root.actions.js';
import Api from '../../utils/Api.js';
import FilaMenu from "./FilaMenu";
import opcionesMenu from "./opcionesMenu";
import './Sidebar.scss';


function mapStateToProps(state) {
	return ({
		cliente: state.root.cliente,
		login: state.root.login,
	})
}

class SidebarButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			class: ""
		};

		this.openSidebar = this.openSidebar.bind(this);
		this.closeSidebar = this.closeSidebar.bind(this);
	}

	closeSidebar() {
		this.setState(state => ({
			class: ""
		}));
	}

	openSidebar() {
		this.setState(state => ({
			class: "active"
		}));
	}

	render() {
		return (
			<div>
				<Button
					variant="danger"
					style={{ border: 0 }} onClick={this.openSidebar}>
					<FaAlignJustify />
				</Button>
				<Sidebar
					className={this.state.class}
					dispatch={this.props.dispatch}
					closeSidebar={this.closeSidebar}
					agente={this.props.cliente}
					login={this.props.login}
				/>
				<SidebarOverlay className={this.state.class} />
			</div>
		)
	}
}

class Sidebar extends React.Component {

	logOut(dispatch, login) {
		let api = new Api();
		api.delete_token();
		dispatch(updateLogin({ loggedIn: false }))
	}

	render() {
		return (
			<nav id="sidebar" className={this.props.className}>
				<div id="dismiss" onClick={this.props.closeSidebar}>
					<FaArrowLeft />
				</div>

				<div className="sidebar-header">
					<img src={Logo} height="20" width="100" alt="umeme logo" />
				</div>

				<ul className="list-unstyled components">

					{ opcionesMenu?.map( (opcion, index) => <FilaMenu opcion={opcion} props={this.props} key={index}/>) }

				</ul>
			</nav>
		);
	}
}


class SidebarOverlay extends React.Component {
	render() {
		return (
			<div id="overlay" className={this.props.className}></div>
		);
	}
}

export default connect(mapStateToProps)(SidebarButton);