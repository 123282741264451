import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Link, Redirect } from 'react-router-dom';
import { Alert } from 'reactstrap';
import Logo from '../../assets/img/logo_color.png';
import { enviarRecuperarPassword } from '../../_services/AxiosCliente';

const redireccionar = (redirect) => {
 if(!redirect) return null

 return <Redirect to='/login'  />
}

const recuperarPassword = (numerosSuma, solucion, nif, email, setError, setRedirect, setOk) => {

  const suma = numerosSuma[0] + numerosSuma[1];

  if(suma !== parseInt(solucion) || nif.length === 0 || email.length === 0) {
    setError(true)
  } else {
    const body = {
      nif: nif.toUpperCase(),
      email,
    }

    enviarRecuperarPassword(body).then(result => {
      setOk(true)
      setError(false)
      setTimeout(() => {
        setOk(false)
        setRedirect(true);
      },
      3000);
    }).catch(error => {
      setError(true)
    })
  }
}

export const RecuperarPassword = (props) => {
  const [ok, setOk] = useState(false)
  const [nif, setNif] = useState('')
  const [email, setEmail] = useState('')
  const [solucion, setSolucion] = useState(0)
  const [error, setError] = useState(false)
  const [numerosSuma, setNumerosSuma] = useState([Math.floor(Math.random() * 10) + 1, Math.floor(Math.random() * 10) + 1])
  const [redirect, setRedirect] = useState(false)
  return (
    <div id="login-page" className="row align-items-center">
      <div className="col-md-6 col-xl-4 mx-auto">
        <div className="login-logo"><img src={Logo} alt="Umeme Logo" /></div>
        <div className="card rounded-0">
          <div className="card-body">
            <form className="form" autoComplete="off" id="formLogin" noValidate="" method="POST">
              <div className="form-group">
                <label htmlFor="nif">NIF</label>
                <input type="text" className="form-control form-control-lg rounded-0" name="username"
                  id="nif"
                  required=""
                  value={nif}
                  onChange={(e) => setNif(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo electrónico</label>
                <input type="text" className="form-control form-control-lg rounded-0" name="email"
                  id="email"
                  required=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="form-group">
                <label>{numerosSuma[0]} + {numerosSuma[1]} = ?</label>
                <input
                  type="string"
                  className="form-control form-control-lg rounded-0"
                  id="sol"
                  name="password"
                  value={solucion}
                  onChange={(e) => setSolucion(e.target.value)} />
              </div>
              { error
                ? <div className="form-group">
                  <label style={{ color: 'red' }}>Revisa los datos</label>
                </div>
                : ''
              }
              <Link to='/login'>Volver a login</Link>
              <button
                type="button"
                onClick={() => recuperarPassword(numerosSuma, solucion, nif, email, setError, setRedirect, setOk)}
                className="btn btn-success btn float-right"
                id="btnForgot"
              >
                Acceder
              </button>
            </form>
          </div>
          {redireccionar(redirect)}
        </div>
        <div className='mt-1' style={{ visibility: !ok ? 'hidden' : 'visible'}}>
            <Alert color='success'><FaCheck/> Envío correcto. Consulte su correo para iniciar la recuperación de contraseña</Alert>
        </div>
      </div>
    </div>
  );
}
