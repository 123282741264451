
import React from 'react';
import { Link } from "react-router-dom";
import './FilaMenu.scss';

/**
 * Muesta una fila con icono y título, clicable que envía a su ruta
 */
export default ({
	opcion, // Objeto que contiene ruta, titulo y icono (tipo componente)
	props, // Se necesita de la función closeSidebar
	key // Un id para identificar cada fila
}) => {
	return (
		<li key={key}>

			<Link to={opcion?.ruta} onClick={props.closeSidebar}>
				<div className="contenedorPrincipal">

					<div className="contenedorIcono" >
						{opcion?.icono}
					</div>

					<span className="textoTitulo">
						{opcion?.titulo}
					</span>

				</div>
			</Link>

		</li>
	)
}