import axios_creator from "axios";
import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import Logo from "../../assets/img/logo_color.png";
import LoadingIcon from "../../assets/img/Spin-1s-200px.svg";
import config from "../../config.js";
import { updateCliente, updateLogin } from "../../redux/actions/root.actions";
import Api from "../../utils/Api.js";
import User from "../../_services/User.js";
import "./Login.scss";



export const axiosCreate = (url = null) => {
	const realURL = url || `${config.apiConfig.URL}/api/v1/`;
	const axios = axios_creator.create({
		baseURL: realURL,
		timeout: 20000,
		headers: {
			Authorization: "Bearer " + localStorage.getItem("access_token"),
		},
	});
	return axios;
};

function mapStateToProps(state) {
	return {
		login: state.root.login,
		cliente: state.root.cliente,
	};
}

class LoginApp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			username: "",
			password: "",
			loggedIn: false,
			noEsAgente: false,
			cargando: false
		};

		this.login = this.login.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}


	componentDidMount() {
		let loggedIn = true;
		let api = new Api();
		if (api.token === null || api.token === undefined || api.token === "") {
			loggedIn = false;
		}
		this.props.dispatch(
			updateLogin({ ...this.props.login, loggedIn, loaded: true })
		);
	}

	componentDidUpdate(prevProps) {
		if (this.props.login !== prevProps.login) {
			this.forceUpdate();
		}
	}

	login() {
		
		if (this.state.cargando == true) return
		
		this.setState({ error: false });
		
		this.state.cargando = true 

		let login = new Api();

		const username = /[0-9]{7}/.test(this.state.username)
			? this.state.username.toUpperCase()?.trim()
			: this.state.username?.trim();

		login
			.make_login_call(username, this.state.password)
			.then((data) => {
				if ("access_token" in data) {
					const axios = axiosCreate();
					axios.get("/user/profile/?cliente=1").then((response) => {
						if (!response.data.cliente || !response.data.cliente.id) {
							
							this.props.dispatch(
								updateLogin({
									...this.props.login,
									loggedIn: false,
									loaded: true,
								})
							);
							this.setState({
								noEsCliente: true,
								cargando: false
							});
						} else {
							
							this.props.dispatch(
								updateLogin({
									...this.props.login,
									loggedIn: true,
									loaded: true,
								})
							);
							this.props.dispatch(updateCliente({ ...response.data }));
							this.setState({
								noEsCliente: false,
								cargando: false
							});
							new User();
						}
					});
				}
			})
			.catch((e) => {
				this.props.dispatch(
					updateLogin({ ...this.props.login, loggedIn: false, loaded: true })
				);
				this.setState({
					error: true,
					noEsAgente: false,
					cargando: false
				});
			});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleKeyDown = (event) => {
		if (event.key === "Enter") {
			this.login();
		}
	};

	render() {
		return (
			<div id="login-page" className="row align-items-center">
				<div className="col-md-6 col-xl-4 mx-auto">
					<div className="login-logo">
						<img src={Logo} alt="Umeme Logo" />
					</div>
					<div className="card rounded-0">
						<div className="card-body">
							<form
								className="form"
								autoComplete="off"
								id="formLogin"
								noValidate=""
								method="POST"
							>
								<div className="form-group">
									<label htmlFor="id_username">NIF</label>
									<input
										type="text"
										className="form-control form-control-lg rounded-0"
										name="username"
										id="id_username"
										required=""
										value={this.state.user}
										onChange={this.handleInputChange}
									/>
									<div className="invalid-feedback">
										Oops, you missed this one.
                  				</div>
								</div>
								<div className="form-group">
									<label>Contraseña</label>
									<input
										type="password"
										className="form-control form-control-lg rounded-0"
										id="id_password"
										required=""
										name="password"
										onKeyDown={this.handleKeyDown}
										value={this.state.password}
										onChange={this.handleInputChange}
									/>
									<div className="invalid-feedback">
										Enter your password too!
                  					</div>
								</div>

								{this.state.error ? (
									<div className="form-group">
										<label style={{ color: "red" }}>
											Usuario o contraseña inválidos.
                    					</label>
									</div>
								) : (
									""
								)}
								{this.state.noEsCliente ? (
									<div className="form-group">
										<label style={{ color: "red" }}>
											Este usuario no es cliente de Umeme.
                    					</label>
									</div>
								) : (
									""
								)}

								<div style={{display: 'flex'}}>

									<div style={{flex: 1}}>
										<Link to="/forgot">He olvidado mi contraseña</Link>
									</div>

									<div style={{flex: 1}}>
										<button
											disabled={this.state.cargando}
											type="button"
											onClick={this.login}
											className="btn btn-success btn float-right"
											id="btnLogin"
										>
											<div className="contenedorBotonAcceder">
												{ this.state.cargando &&
														
													<div className="contenedorImagenLoading">
														<img 
															src={LoadingIcon}
															alt="Loading"
															style={{height: '90%', width: '90%', display: 'flex'}}
														/>
													</div>
												}
												
												<span className="contenedorTextoAcceder">
													Acceder 
												</span>
											</div>
											
										</button>
									</div>

								</div>

								
								

							</form>
						</div>
					</div>
					<Alert className="mt-1" color={"danger"}>
						{" "}
						<FaExclamationTriangle /> Para iniciar sesión en el Panel de
            Clientes por primera vez, debe pulsar en “He olvidado mi contraseña”
            para generar una nueva contraseña única y segura de acceso a la
            aplicación.
          </Alert>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(LoginApp);
