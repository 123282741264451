import React from 'react'
import PropTypes from 'prop-types'
import styles from './Landing.module.css'
import { Link } from 'react-router-dom'

const Landing = () => {
  return (
    <div className={styles.landing}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img
              src='/images/logo.png'
              alt='logo'
              width={'214px'}
              height={'40px'}
            />
          </div>
          <div className={styles.button}>
            <a href='https://clientes.umeme.es/login'>
              <button className={styles.button}>Acceso Clientes</button>
            </a>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.octopuslogo}>
            <img src='/images/constantine.png' alt='logo' width='100%' />
          </div>
          <div className={styles.text}>
            <h1>Umeme ahora es Octopus Energy</h1>
            <p>
              Nos hemos unido a{' '}
              <a
                href='https://www.octopusenergy.es/'
                target='_blank'
                rel='noreferrer'
              >
                Octopus Energy
              </a>{' '}
              para ofrecer energía verde a un precio justo.
            </p>
            <p>
              Si eres usuario de Umeme, no te preocupes: seguirás teniendo
              suministro con normalidad y puedes seguir accediendo a tu
              información en tu&nbsp;
              <a href='https://clientes.umeme.es/login'>área de clientes</a>.
            </p>
            <p>
              Además, si tienes cualquier duda, puedes ponerte en contacto con
              nosotros en el <strong> teléfono gratuito 900 877 878.</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

Landing.propTypes = {}

export default Landing
