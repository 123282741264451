import axios_creator from 'axios';
import config from '../config.js';

function createAxios() {
  const axios = axios_creator.create({
    baseURL: `${config.apiConfig.URL}/api/v1/`,
    timeout: 20000,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return axios;
}

export const login = (user, password) => new Promise(((resolve, reject) => {
  fetch(`${config.apiConfig.URL}/o/token/`, {
    method: 'POST',
    body: `grant_type=password&username=${user}&password=${password}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${config.apiConfig.AUTH}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        reject(response);
        throw new Error(response);
      }
      return response.json();
    })
    .then((myJson) => {
      localStorage.setItem('access_token', myJson.access_token);
      localStorage.setItem('refresh_token', myJson.refresh_token);
      resolve(myJson);
    })
    .catch((error) => console.error(error));
}));

export const checkToken = () => {
  const access_token = localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');
  return new Promise((resolve, reject) => {
    if (access_token === null || access_token === undefined || access_token === '') {
      if (refresh_token === null || refresh_token === undefined || refresh_token === '') {
        get_new_token().then((data) => resolve(data)).catch((err) => reject(err));
      } else {
        reject();
      }
    } else {
      const axios = createAxios();
      axios.get('/user/profile/?cliente=1').then(
        (data) => {
          resolve(data);
        },
      ).catch(
        (e) => {
          get_new_token()
            .then((data) => resolve(data))
            .catch((err) => reject(err));
        },
      );
    }
  });
};

export const get_new_token = () => new Promise((resolve, reject) => {
  fetch(`${config.apiConfig.URL}/o/token/`, {
    method: 'POST',
    body: `grant_type=refresh_token&client_id=${config.apiConfig.PUBLIC}&client_secret=${config.apiConfig.SECRET}&refresh_token=${localStorage.getItem('refresh_token')}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${config.apiConfig.AUTH}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        reject(response);
        throw new Error(response);
      }
      return response.json();
    })
    .then((myJson) => {
      localStorage.setItem('access_token', myJson.access_token);
      localStorage.setItem('refresh_token', myJson.refresh_token);
      resolve(myJson);
    })
    .catch((error) => console.error(error));
});
