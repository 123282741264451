import {
  UPDATE_FACTURA_INFO,
  UPDATE_FACTURAS_LIST,
  UPDATE_MODALES_FACTURAS,
  UPDATE_BUSQUEDA_FACTURAS,
  UPDATE_CHECKERS_FACTURAS,
  UPDATE_FACTURA_FROM_LIST
} from '../constants/facturas.action-types';


export const updateFactura = (factura) => {
  return { type: UPDATE_FACTURA_INFO, factura }
}

export const updateFacturaOnList = (factura) => {
  return { type: UPDATE_FACTURA_FROM_LIST, factura }
}



export const updateListaFacturas = (listado) => {
  return { type: UPDATE_FACTURAS_LIST, listado }
}

export const updateModalesFacturas = (modales) => {
  return { type: UPDATE_MODALES_FACTURAS, modales }
}

export const updateBusquedaFacturas = (busqueda) => {
  return { type: UPDATE_BUSQUEDA_FACTURAS, busqueda }
}

export const updateCheckersFacturas = (checkers) => {
  return { type: UPDATE_CHECKERS_FACTURAS, checkers }
}
