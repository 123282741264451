const dev = {
  apiConfig: {
    URL: "http://teslalocal.umeme.es",
    URLFACTURACION: "http://facturacionlocal.umeme.es",
    URLGRAPHQL: "https://api.umeme.es/v1/graphql",
    PUBLIC: "zCz8E9aFwMzV538XPaeeuQ5uovw8q81bdppZ1175",
    SECRET: "PyUdr4rRzV7LwS9YMD9CimD8va7zDZ1ObMLUgSl6zy3TGkCbfSFEGkOV1VCGT2bBgDRHE1amhjwK7dhFq0bhYbcndPDuIO0Np8c2SUeUBwulbqnKzcGpJZxFSxy9KR4W",
    AUTH: "ekN6OEU5YUZ3TXpWNTM4WFBhZWV1UTV1b3Z3OHE4MWJkcHBaMTE3NTpQeVVkcjRyUnpWN0x3UzlZTUQ5Q2ltRDh2YTd6RFoxT2JNTFVnU2w2enkzVEdrQ2JmU0ZFR2tPVjFWQ0dUMmJCZ0RSSEUxYW1oandLN2RoRnEwYmhZYmNuZFBEdUlPME5wOGMyU1VlVUJ3dWxicW5LemNHcEpaeEZTeHk5S1I0Vw=="
  },
};

const pre = {
  apiConfig: {
    URL: "https://teslapre.umeme.es",
    URLFACTURACION: "https://facturacionpre.umeme.es",
    URLGRAPHQL: "https://api.umeme.es/v1/graphql",
    PUBLIC: "zCz8E9aFwMzV538XPaeeuQ5uovw8q81bdppZ1175",
    SECRET: "PyUdr4rRzV7LwS9YMD9CimD8va7zDZ1ObMLUgSl6zy3TGkCbfSFEGkOV1VCGT2bBgDRHE1amhjwK7dhFq0bhYbcndPDuIO0Np8c2SUeUBwulbqnKzcGpJZxFSxy9KR4W",
    AUTH: "ekN6OEU5YUZ3TXpWNTM4WFBhZWV1UTV1b3Z3OHE4MWJkcHBaMTE3NTpQeVVkcjRyUnpWN0x3UzlZTUQ5Q2ltRDh2YTd6RFoxT2JNTFVnU2w2enkzVEdrQ2JmU0ZFR2tPVjFWQ0dUMmJCZ0RSSEUxYW1oandLN2RoRnEwYmhZYmNuZFBEdUlPME5wOGMyU1VlVUJ3dWxicW5LemNHcEpaeEZTeHk5S1I0Vw=="
  },
};

const prod = {
  apiConfig: {
    URL: "https://tesla.umeme.es",
    URLFACTURACION: "https://facturacion.umeme.es",
    URLGRAPHQL: "https://api.umeme.es/v1/graphql",
    PUBLIC: "zCz8E9aFwMzV538XPaeeuQ5uovw8q81bdppZ1175",
    SECRET: "PyUdr4rRzV7LwS9YMD9CimD8va7zDZ1ObMLUgSl6zy3TGkCbfSFEGkOV1VCGT2bBgDRHE1amhjwK7dhFq0bhYbcndPDuIO0Np8c2SUeUBwulbqnKzcGpJZxFSxy9KR4W",
    AUTH: "ekN6OEU5YUZ3TXpWNTM4WFBhZWV1UTV1b3Z3OHE4MWJkcHBaMTE3NTpQeVVkcjRyUnpWN0x3UzlZTUQ5Q2ltRDh2YTd6RFoxT2JNTFVnU2w2enkzVEdrQ2JmU0ZFR2tPVjFWQ0dUMmJCZ0RSSEUxYW1oandLN2RoRnEwYmhZYmNuZFBEdUlPME5wOGMyU1VlVUJ3dWxicW5LemNHcEpaeEZTeHk5S1I0Vw=="
  },
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : process.env.REACT_APP_STAGE === 'pre'
    ? pre
    : pre;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
