import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import { RouterViews } from './views/RouterViews';
import 'typeface-roboto'


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RouterViews />
      </Provider>
    );
  }
}

export default App;