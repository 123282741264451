import React, { useEffect, useState } from "react";
import { FaFilePdf, FaQuestion } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import Filtro from "../../components/common/filtro/FiltroComponente";
import { MensajesError } from "../../components/common/mensajesError/MensajesError";
import PantallaCarga from "../../components/common/pantallaCarga/PantallaCarga";
import SinResultados from "../../components/common/sinResultados/SinResultados";
import config from "../../config";
import {
  updateBusquedaFacturas,
  updateCheckersFacturas,
  updateFacturaOnList,
  updateListaFacturas
} from "../../redux/actions/facturas.actions";
import { get_query } from "../../_services/utils";
import { downloadFile } from "../utils/common/downloader";
import { checkNegocio, numberFormatter } from "../utils/common/formatters";
import {
  handleFecha
} from "../utils/handlers";
import {
  handleDireccionCompleta,
  handleError,
  query_listado_facturas
} from "../utils/utils";
import formatAddress from "../../utils/addressFormatter";
import { FilaPaginacion } from "../../components/common/FilaPaginacion/FilaPaginacion";
import AlertaError from "../../components/AlertaError/AlertaError";

const dataFiltro = require("./dataFiltro.json");

const updateBusqueda = (dispatch, busqueda) => {
  dispatch(updateBusquedaFacturas(busqueda));
};

const updateLista = (dispatch, busqueda, checkers, funcionCargando) => {
  retrieveListadoFacturas(dispatch, busqueda, checkers, funcionCargando);
};

const createTable = (listado = [], setMostrarAlerta) => {
  const elementosTabla = [];
  if (listado?.length === 0) return;

  for (let i = 0; i < listado.length; i += 1) {
    const factura = listado[i];

    elementosTabla.push(
      <tr key={i}>
        <th scope="row">
          {factura?.negocio ? (
            checkNegocio(factura?.negocio == "ELE" ? 1 : 2)
          ) : (
            <FaQuestion />
          )}
        </th>
        <td className="d-none d-sm-table-cell">{factura?.num_factura}</td>
        <td className="d-none d-sm-table-cell">
          {factura?.albaranes_albarans?.producto}
        </td>
        <td className="d-none d-sm-table-cell">{factura?.direccion_completa}</td>
        <td>{handleFecha(factura?.fecha_factura)}</td>
        <td className="d-none d-sm-table-cell">{getPeriodo(factura)}</td>
        <td className="text-right">{numberFormatter(factura?.total_factura)} €</td>
        <td>
          {acciones(factura?.documento_id, `${factura?.num_factura}.pdf`, setMostrarAlerta)}
        </td>
      </tr>
    );
  }

  return elementosTabla;
};

const acciones = (documento, filename, setMostrarAlerta) => {
  const path = `/api/v1/documentos/${documento ? documento : ""}`;
  
  return (
    <Button
      disabled={!documento}
      size="sm"
      color={"danger"}
      onClick={ async () => {
        let resultado = await downloadFile(
          config.apiConfig.URLFACTURACION,
          path,
          filename
        )

        if (!resultado) setMostrarAlerta(true)
      }}
    >
        <FaFilePdf />
    </Button>
  );
};

const getPeriodo = (factura) => {
  const fecha_desde_pot =
    factura?.albaranes_albarans?.fecha_desde_potencia?.aggregate?.min
      ?.fecha_desde;
  const fecha_hasta_pot =
    factura?.albaranes_albarans?.fecha_hasta_potencia?.aggregate?.max
      ?.fecha_hasta;
  const fecha_desde =
    factura?.albaranes_albarans?.fecha_desde?.aggregate?.min?.fecha_desde;
  const fecha_hasta =
    factura?.albaranes_albarans?.fecha_hasta?.aggregate?.max?.fecha_hasta;

  if (fecha_desde_pot && fecha_hasta_pot) {
    return "del " + fecha_desde_pot + " al " + fecha_hasta_pot;
  } else {
    return "del " + fecha_desde + " al " + fecha_hasta;
  }
};

const retrieveListadoFacturas = async (
  dispatch,
  busqueda,
  checkers,
  setCargando
) => {

  if (busqueda.nif) {

    const variables = { nif: busqueda?.nif };

    setCargando(true);

    const newBusqueda = {
      query: query_listado_facturas,
      variables: variables,
    };

    const response = await get_query(newBusqueda).catch((error) => {
      setCargando(false);
      handleError(dispatch, updateCheckersFacturas, checkers, "errorListado");
    });

    setCargando(false);

    const facturas = {
      results: response?.data?.data?.facturacion_facturas_emitidas_facturaemitida,
    };

    dispatch(updateListaFacturas(facturas?.results));


		// * Esto se encarga de pintar las direcciones en el listado de facturas
		if (facturas?.results) facturas.results.map((f) => {
			const direccion = f.albaranes_albarans?.producto_object?.accounts_userdireccion;
			f.direccion_completa = formatAddress(direccion);
		});
  }
};

export const ListaFacturas = () => {

  const dispatch = useDispatch();

  // Selectores
  const facturas = useSelector((state) => state.facturas.listado);
  const cliente = useSelector((state) => state.root.cliente);
  const busqueda = useSelector((state) => state.facturas.busqueda);
  const checkers = useSelector((state) => state.facturas.checkers);

  // Estados
  const [toggleFiltro, setToggleFiltro] = useState(false);
  const [listaFacturaFiltrada, setListaFacturaFiltrada] = useState(facturas?.results);
  const [cargando, setCargando] = useState(false);
  const [limit, setLimit] = useState(10);
  const [desdeFactura, setDesdeFactura] = useState(0);
  const [hastaFactura, setHastaFactura] = useState(limit);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);

  // Funciones
  const cambiarPagina = (botonSeleccionado) => {
    let desdeActual = desdeFactura;
    let hastaActual = hastaFactura;

    switch (botonSeleccionado) {
      case "<":
        if (desdeActual == 0) return;

        setDesdeFactura(desdeActual - limit);
        setHastaFactura(hastaActual - limit);
        break;

      case ">":
        if (desdeActual + limit > facturas?.results?.length) return

        setDesdeFactura(desdeActual + limit);
        setHastaFactura(desdeActual + limit + limit);
        break;

      default:
        setDesdeFactura(0);
        setHastaFactura(0 + limit);
    }
  };

  // UseEffects
  useEffect(() => {
    updateBusqueda(dispatch, {
      ...busqueda,
      cliente: cliente.cliente.id,
      nif: cliente.nif,
    });
  }, [cliente]);

  useEffect(() => {
    cambiarPagina();
  }, [limit]);

  useEffect(() => {
    if (facturas?.results && facturas?.results?.length > 0) {
      let parteFacturas = facturas?.results;
      setListaFacturaFiltrada(parteFacturas);
    }
  }, [busqueda, facturas]);

  useEffect(() => {
    retrieveListadoFacturas(dispatch, busqueda, checkers, setCargando);
  }, [busqueda]);



  return (
    <>
      <div className="col-md-12 row px-5 py-2">
        <div className="col-md-6">
          <h4>Facturas</h4>
        </div>
        <div className="col-md-2" />
        <div className="float-right pl-2 col-md-4">
          <MensajesError checkers={checkers} />
        </div>
      </div>
      <div className="col-md-12 row">
        {/* <div className="col-md-9 my-2 pt-4 px-5">
          <Button color="danger" onClick={() => setToggleFiltro(!toggleFiltro)}>
            Filtros
          </Button>
        </div> */}
        {/* <div className="col-md-3 my-2">
          <label htmlFor="selector-cantidad">Buscar</label>
          <input
            id="campo-busqueda"
            name="busqueda"
            className="form-control"
            type="text"
            placeholder="Nº factura, C.U.P.S"
            aria-label="Buscar"
            onChange={(e) =>
              updateBusqueda(dispatch, { ...busqueda, search: e.target.value })
            }
            value={busqueda.search}
          />
        </div> */}
      </div>
      <div className="col-md-12 my-2 row">
        <Filtro
          data={dataFiltro.data}
          isOpen={toggleFiltro}
          busqueda={busqueda}
          checkers={checkers}
          prop={busqueda}
          updateBusqueda={updateBusqueda}
          updateLista={updateLista}
          dispatch={dispatch}
          funcionCargando={setCargando}
        />
      </div>
      <div className="col-md-12">
        {cargando ? (
          <PantallaCarga />
        ) : !facturas.results || !facturas.results.length ? (
          <SinResultados />
        ) : (
          <Table
            size="sm"
            hover
            responsive
            className="justify-content-center"
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th
                  className="d-none d-sm-table-cell"
                  // onClick={() => sortBy("negocio")}
                >
                  <Button color="white">
                    <div className="row">
                      Tipo
                      {/* {handleSorts(busqueda, "negocio")} */}
                    </div>
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell"
                  // onClick={() => sortBy("num_factura")}
                >
                  <Button color="white">
                    <div className="row">
                      Nº factura
                      {/* {handleSorts(busqueda, "num_factura")} */}
                    </div>
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell row"
                  // onClick={() => sortBy("producto")}
                >
                  <Button color="white">
                    <div className="row">
                      Punto de suministro
                      {/* {handleSorts(busqueda, "producto")} */}
                    </div>
                  </Button>
                </th>
                <th
                  className="d-none d-sm-table-cell row"
                  // onClick={() => sortBy("direccion")}
                >
                  <Button color="white">
                    <div className="row">
                      Dirección
                      {/* {handleSorts(busqueda, "direccion")} */}
                    </div>
                  </Button>
                </th>
                <th 
                // onClick={() => sortBy("fecha_factura")}
                >
                  <Button color="white">
                    <div className="row">
                      Fecha factura
                      {/* {handleSorts(busqueda, "fecha_factura")} */}
                    </div>
                  </Button>
                </th>
                <th className="d-none d-sm-table-cell row">
                  <Button color="white">
                    Periodo factura
                    </Button>
                </th>
                <th className="text-right">
                  <Button color="white">
                    <div className="row text-right">
                      Total factura
                    </div>
                  </Button>
                </th>
                <th>
                  <Button color="white">Descargar Factura</Button>
                </th>
              </tr>
            </thead>

            <tbody>
              {createTable(
                facturas?.results?.slice(desdeFactura, hastaFactura),
                setMostrarAlerta
              )}
            </tbody>

           
          </Table>
        )}

        { facturas?.results?.length > 10 && 
          
          <FilaPaginacion 
            totalResultados={facturas?.results?.length}
            functionPulsaBoton={cambiarPagina}
            limiteDeElementos={limit}
            setLimiteDeElementos={setLimit}
            deshabilitadoBotonHaciaAtras={desdeFactura === 0}
            deshabilitadoBotonHaciaDelante={hastaFactura > facturas?.results?.length}
          />
          
        }
      </div>

      { mostrarAlerta && <AlertaError mostrar={mostrarAlerta} setMostrar={setMostrarAlerta} /> }

      <div style={{ height: "100px" }}></div>

    </>
  );
};
