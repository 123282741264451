import React from 'react'
import dataJson from './formatters.json'
import { FaQuestion } from 'react-icons/fa';

export const handleError = (dispatch, callback, prop, variable) => {
  dispatch(callback({ ...prop, [variable]: true }))
  setTimeout(() => {
    dispatch(callback({ ...prop, [variable]: false }));
  },
    3000);

}

export const handleErrorState = (callback, prop, variable) => {
  callback({ ...prop, [variable]: true })
  setTimeout(() => {
      callback({ ...prop, [variable]: false });
    },
    3000);
}

export const checkEstadoContrato = (estado) => {
  switch (estado) {
    case 'PENDIENTE':
      return <font color={"#dc3545"}>NO VALIDADO</font>
    case 'VALIDADO':
      return <font color={"#f48607"}>VALIDADO</font>
    case 'TRAMITADO':
      return <font color={"#17a2b8"}>TRAMITADO</font>
    case 'POTENCIAL':
      return <font color={"#ffc107"}>POTENCIAL</font>
    case 'ACTIVO':
      return <font color={"#28a745"}>ACTIVO</font>
    case 'RECHAZADO':
      return <font color={"#6c757d"}>RECHAZADO</font>
    case 'BAJA':
      return <font color={"#6c757d"}>BAJA</font>
    case 'NULO':
      return <font color={"#6c757d"}>NULO</font>
    default:
      return estado
  }
}

export const handleDireccionCompletaContrato = (contrato) => {
  if (!contrato) return <FaQuestion />
  if (!contrato.producto) return <FaQuestion />
  let direccion = contrato.producto.direccion
  let direccion_str = direccion?.tipo_via + ' ' + direccion?.nombre_via + ', ' + direccion?.numero_via
  if (direccion?.puerta != '') direccion_str += ', ' + direccion?.puerta
  if (direccion?.portal != '') direccion_str += ', PORTAL ' + direccion?.portal
  if (direccion?.escalera != '') direccion_str += ', ESC ' + direccion?.escalera
  if (direccion?.bloque != '') direccion_str += ', BLOQUE ' + direccion?.bloque
  if (direccion?.piso != '') direccion_str += ', PISO ' + direccion?.bloque
  direccion_str += ', ' + direccion?.cod_postal + ', ' + direccion?.localidad
  if (direccion?.aclarador_finca != '') direccion_str += ', ACL. ' + direccion?.aclarador_finca
  return direccion_str
}

export const handleDireccionCompleta = (direccion) => {
  let direccion_str = direccion?.tipo_via + ' ' + direccion?.nombre_via + ', ' + direccion?.numero_via
  if (direccion?.puerta != '') direccion_str += ', ' + direccion?.puerta
  if (direccion?.portal != '') direccion_str += ', PORTAL ' + direccion?.portal
  if (direccion?.escalera != '') direccion_str += ', ESC ' + direccion?.escalera
  if (direccion?.bloque != '') direccion_str += ', BLOQUE ' + direccion?.bloque
  if (direccion?.piso != '') direccion_str += ', PISO ' + direccion?.bloque
  // direccion_str += ', ' + direccion?.cod_postal + ', ' + direccion?.localidad
  if (direccion?.aclarador_finca != '') direccion_str += ', ACL. ' + direccion?.aclarador_finca
  return direccion_str
}




export const formatMonths = (data = [{}]) => {
  for (let i = 0; i < data.length; i++) {
    data[i].mes = dataJson.date[data[i].mes.toString()]
  }

  return data
}

export const uniqSumBy = (data, variableName = "", variableSum = "") => {
  const values = data.map(value => value[variableName])
  const uniqValues = Array.from(new Set(values))
  const results = [];
  for (let i = 0; i < uniqValues.length; i++) {
    const value = uniqValues[i];
    const filter = data.filter(obj => obj[variableName] === value)
    const filterSum = filter.map(obj => obj[variableSum])
    const sum = filterSum.reduce((a, b) => a + b)
    results.push({
      [variableName]: value,
      [variableSum]: sum.toFixed(2),
    })
  }

  return results
}

export const distribuidorasGas = ['0201', '0202', '0203', '0204', '0205', '0206', '0207', '0208',
'0210', '0211', '0213', '0216', '0217', '0218', '0219',
  '0220', '0221', '0222', '0223', '0224', '0226',
  '0227', '0228', '0229', '0230', '0234', '0236', '0237', '0238',
  '0238', '0239', '0240', '0242'];

  export const query_listado_contratos = `
    query ($nif: String!) {
        comercial_contrato(where: {comercial_cliente: {accounts_customuser: {nif: {_eq: $nif}}}, estado: {_neq: "NULO"}}) {
        num_contrato
        estado
        fecha_inicio
        fecha_fin
        modelo_contrato
        contrato_archivo_id
        producto: comercial_producto {
            CUPS: codigo
            negocio: negocio_id
            direccion: accounts_userdireccion {
            tipo_via
            nombre_via
            numero_via
            portal
            escalera
            bloque
            piso
            puerta
            poblacion
            localidad
            provincia
            cod_postal
            aclarador_finca
            }
        }
        }
    }
    `;
    
export const query_listado_facturas = `
query ($nif: String!) {
  facturacion_facturas_emitidas_facturaemitida (
    where: { 
      _and: 
      { 
        nif: {_eq: $nif}, 
        num_factura: {_is_null:false},
        deleted_at:{_is_null: true}
      }
    },order_by:{id: desc}
    ) {
    num_factura
    negocio
    albaranes_albarans {
      producto
      fecha_desde_potencia: albaranes_lineaalbarans_aggregate(where: {conceptos_recurso: {nombre: {_eq: "01"}}}) {
        aggregate {
          min {
            fecha_desde
          }
        }
      }
      fecha_desde: albaranes_lineaalbarans_aggregate {
        aggregate {
          min {
            fecha_desde
          }
        }
      }
      fecha_hasta_potencia: albaranes_lineaalbarans_aggregate(where: {conceptos_recurso: {nombre: {_eq: "01"}}}) {
        aggregate {
          max {
            fecha_hasta
          }
        }
      }
      fecha_hasta: albaranes_lineaalbarans_aggregate {
        aggregate {
          max {
            fecha_hasta
          }
        }
      }
      producto_object {
        accounts_userdireccion {
          tipo_via,
          nombre_via,
          numero_via,
          bloque,
          piso,
          portal,
          escalera,
          aclarador_finca,
          puerta
        }
      }
    }
    fecha_factura
    total_factura
    documento_id
  }
}
`;


