
import {
  UPDATE_FACTURA_INFO,
  UPDATE_FACTURAS_LIST,
  UPDATE_MODALES_FACTURAS,
  UPDATE_BUSQUEDA_FACTURAS,
  UPDATE_CHECKERS_FACTURAS,
  UPDATE_FACTURA_FROM_LIST,
} from "../constants/facturas.action-types";

export const initialState = {
  listado: {
    count: 0,
    results: [],
  },
  factura: {},
  modales: {},
  busqueda: {
    limit: 10,
    offset: 0,
    paginaActual: 0,
    sort: "fecha_factura",
    order: "desc",
  },
  checkers: {},
};

function facturasReducer(state = initialState, action) {

  switch (action.type) {
    
    case UPDATE_FACTURA_INFO:
      return Object.assign({}, state, {
        factura: action.factura,
      });

    case UPDATE_FACTURA_FROM_LIST:

      let listado_facturas = state?.listado?.results;
     
      if (listado_facturas?.length > 0) {

        let copiaListado = listado_facturas;
      
        listado_facturas.forEach( (fac, i) => {

          if (fac?.num_factura == action?.factura?.num_factura) {
            listado_facturas[i] = action?.factura
          }

        })
      }

      return { 
        ...state,
        listado: {
          results: [...listado_facturas]
          // results: [listado_facturas]
          //   ...state.listado.results.filter(
          //     (i) => i?.num_factura !== action?.factura?.num_factura
          //   ),
          //   // ...state.listado.results, 
          //   action.factura,
          // ],
        },
      };

    case UPDATE_FACTURAS_LIST:
      return {
        ...state,
        listado: {
            results: 
                [...action.listado]
            
        },
      };
    case UPDATE_MODALES_FACTURAS:
      return Object.assign({}, state, {
        modales: action.modales,
      });
    case UPDATE_BUSQUEDA_FACTURAS:
      return Object.assign({}, state, {
        busqueda: action.busqueda,
      });
    case UPDATE_CHECKERS_FACTURAS:
      return Object.assign({}, state, {
        checkers: action.checkers,
      });
    default:
      return state;
  }
}

export default facturasReducer;
