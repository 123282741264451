import config from '../config.js'
import axios_creator from 'axios';

export const axios = axios_creator.create({
    baseURL: `${config.apiConfig.URL}/api/v1/`,
    timeout: 20000,
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }
})

export const axiosCreate = (url = null) => {
  const realURL = url || `${config.apiConfig.URL}/api/v1/`;
  const axios = axios_creator.create({
  baseURL: realURL,
  timeout: 20000,
  headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }
})
  return axios
}

export const URL_SEARCH_MUNICIPIO = "search/municipio/" ;
export const URL_SEARCH_VIA = "search/via/" ;
//export const URL_SEARCH_NOMBRE_VIA = "search/nombre_via";


class Api {

    constructor(url_name, pk, params) {
        this.host = config.apiConfig.URL;
        this.api_version = "/api/v1/";
        this.api_url = `${this.host}${this.api_version}`;
        this.url = this.url_library(url_name, pk, params);
        this.secret_key = config.apiConfig.SECRET;
        this.public_key = config.apiConfig.PUBLIC;
        this.Auth = config.apiConfig.AUTH;
        this.access_token = localStorage.getItem('access_token');
        this.refresh_token = localStorage.getItem('refresh_token');
    }

    url_library(name, pk, params) {
        let urls = {
            'user': 'user/',
            'email': 'user/email/',
            'phone': 'user/phone/',
            'search_municipio': 'search/municipio/',
            'search_via': 'search/via/',
            'bank_account': 'user/bank-account/',
            'address': 'user/address/',
            'cliente': 'comercial/cliente/',
            'lead': 'comercial/cliente/lead/',
            'sips': 'comercial/contratacion/sips/',
            'producto': 'comercial/producto/',
            'contrato': 'comercial/contratacion/',
            'generar_contrato': 'comercial/contratacion/generar-contrato/',
            'proveedor': 'comercial/proveedor/',
            'subproveedor': 'comercial/proveedor/subproveedor/',
            'agente': 'comercial/proveedor/agente/',
            'personal': 'personal/',
            'precios': 'comercial/proveedor/precios/',
            'precios_agente': 'comercial/proveedor/precios/agente/',
            'calculo_precios': 'comercial/proveedor/precios/calculo/',
            'preciosproveedor': 'comercial/proveedor/precios/canal/',
            'precioformula': 'comercial/proveedor/precio/formula/',
            'tipoproducto': 'comercial/proveedor/tipo_producto/',
            'sips_search': 'search/sips/',
            'municipio_search': 'search/municipio/',
            'compras': 'operaciones/compras/',
            'consumos': 'operaciones/consumos/',
            'consumos_recalculo': 'operaciones/consumos/recalculo/',
            'acivos_financieros': 'operaciones/electricidad/mercado/activos-financieros/',
            'omie_horario': 'operaciones/electricidad/mercado/omie-horario/',
            'futuros_meff': 'operaciones/electricidad/mercado/futuros-meff/',
            'static_data': 'utils/static_data/'

        };
        let url = `${this.api_url}${urls[name]}`;
        if (pk !== undefined && pk !== null) {
            url = `${url}${pk}/`;
        }
        if (params !== undefined && params !== null) {
            let data = Object.entries(params);
            data = data.map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
            let query = data.join('&');
            url = `${url}?${query}`;
        }
        return url;
    }

    make_call(method, data) {
        let that = this;
        if (method === undefined)
            method = "GET";

        return new Promise(function (resolve, reject) {
            fetch(that.url, {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${that.access_token}`
                },
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    if (!response.ok) {
                        reject(response.json());
                    }
                    return response.json();
                })
                .then(response => resolve(response))
                .catch(error => console.error(error))
        });
    }


    make_login_call(user, password)  {
        let that= this;
        return new Promise(function (resolve, reject) {
            fetch(`${that.host}/o/token/`, {
                method: "POST",
               body: `grant_type=password&username=${user}&password=${password}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${that.Auth}`
                },
            })
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        throw new Error(response);
                    }
                    return response.json();
                })
                .then(myJson => {
                    that.token = myJson;
                    localStorage.setItem('access_token', myJson.access_token);
                    localStorage.setItem('refresh_token', myJson.refresh_token);
                    resolve(myJson);
                })
                .catch(error => console.error(error))
        });
    }

    check_token() {
        return new Promise((resolve, reject) => {
            if (this.access_token === null || this.access_token === undefined || this.access_token === '') {
                if (this.refresh_token === null || this.refresh_token === undefined || this.refresh_token === '') {
                    this.get_new_token()
                        .then(data => resolve(data))
                        .catch(err => reject(err))
                } else {
                    reject();
                }
            } else {
                const axios = axiosCreate();
                axios.get('/user/profile/?agente=1').then(
                    data => {
                        resolve(data);
                    }
                ).catch(
                    e => {
                        this.get_new_token()
                            .then(data => resolve(data))
                            .catch(err => reject(err));
                    }
                );
            }
        });
    }

    get_new_token() {
        return new Promise((resolve, reject) => {
            fetch(`${this.host}/o/token/`, {
                method: "POST",
                body: `grant_type=refresh_token&client_id=${this.public_key}&client_secret=${this.secret_key}&refresh_token=${this.refresh_token}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${this.Auth}`
                },
            })
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        throw new Error(response);
                    }
                    return response.json();
                })
                .then(myJson => {
                    this.token = myJson;
                    localStorage.setItem('access_token', myJson.access_token);
                    localStorage.setItem('refresh_token', myJson.refresh_token);
                    resolve(myJson);
                })
                .catch(error => console.error(error))
        });
    }

    delete_token() {
        this.token = {};
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }
}

export default Api;
