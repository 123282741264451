import React from "react";
import "./FilaPaginacion.scss";

/**
 * @typedef {Object} Props
 * @property {number} cantidad Número de elementos deseados por página.
*/

/**
 * Crea opción para componente selector de número de elementos por página.
 * @param {Props} props
*/
const OpcionSelectorCantidad = ({cantidad}) => {
    return(
        <option value={cantidad}>
            {cantidad}
        </option>
    ) 
}



/**
 * @typedef {Object} Props
 * @property {function} functionPulsaBoton Función que se ejecutará cuando se pulse el botón.
 * @property {string} textoBoton Cadena de texto que se muestra dentro del botón.
 * @property {boolean} deshabilitado Determina si puede ser cliclado o no.
*/

/**
 * Crea botón para cargar nuevos elementos del listado de resultados.
 * @param {Props} props
*/
const BotonSelectorPagina = ({
	functionPulsaBoton = () => {},
	textoBoton = "",
	deshabilitado = false,
}) => {
	return (
		<div
        	onClick={() => deshabilitado ? null : functionPulsaBoton(textoBoton)}
		  	class={deshabilitado ? "botonDeshabilitado" : "contenedorBotonSelectorPagina"}
        >
          <span class="textoBotonSelectorPagina">
			  {textoBoton}
			</span>
        </div>
	)
}



/**
 * @typedef {Object} Props
 * @property {boolean} mostrarTotalElementos Determina si se muestra el número total de resultados.
 * @property {boolean} mostrarSelectorCantidad Determina si se muestra el selector de elementos por página.
 * @property {array} opcionesSelectorCantidad Las cantidades deseadas para el selector de elementos por página.
 * @property {number} totalResultados El número total de registros de la tabla.
 * @property {function} functionPulsaBoton Función que se ejecutará cuando se pulse el botón.
 * @property {function} functionPulsaBoton Función que se ejecutará cuando se pulse el botón.
 * @property {number} limiteDeElementos El número de elementos a mostrar por página
 * @property {function} setLimiteDeElementos Función que modifica el estado de limiteDeElementos
 * @property {boolean} deshabilitadoBotonHaciaAtras Determina si el botón de ver elementos anteriores está deshabilitado.
 * @property {boolean} deshabilitadoBotonHaciaDelante Determina si el botón de ver elementos posteriores está deshabilitado.
*/

/**
 *
 * @param {Props} props
 */
export const FilaPaginacion = ({ 
    mostrarTotalElementos = true, 
    mostrarSelectorCantidad = true,
    opcionesSelectorCantidad = [10, 25, 50],
    totalResultados = 0,
    functionPulsaBoton = () => {},
    limiteDeElementos = 10,
    setLimiteDeElementos= () => {},
    deshabilitadoBotonHaciaAtras = false,
    deshabilitadoBotonHaciaDelante = false,
}) => {

  return (

    <div class="contenedorFila">

      <div class="contenedorSelector">
        { mostrarSelectorCantidad &&
            <select
                id="selector-cantidad"
                name="selector-cantidad"
                style={{ width: "200px" }}
                className="form-control form-control-sm col-md-3"
                value={limiteDeElementos}
                onChange={(e) => setLimiteDeElementos(parseInt(e.target.value))}
            >
                {opcionesSelectorCantidad?.map( cantidad => <OpcionSelectorCantidad cantidad={cantidad} /> )}
            </select>
        }

        { mostrarTotalElementos &&
            <span>
                &nbsp; elementos de {totalResultados}
            </span>
        }
      </div>

      <div class="contenedorBotonesSelectorPagina">
		<BotonSelectorPagina 
			functionPulsaBoton={functionPulsaBoton}
			textoBoton={"<"}
			deshabilitado={deshabilitadoBotonHaciaAtras}
		/>

		<BotonSelectorPagina 
			functionPulsaBoton={functionPulsaBoton}
			textoBoton={">"}
			deshabilitado={deshabilitadoBotonHaciaDelante}
		/>
      </div>
    </div>
  );
};
