
import axios_creator from 'axios';

function createAxios(baseUrl) {
    const axios = axios_creator.create({
      baseURL: baseUrl,
      timeout: 20000,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    return axios;
  }

export const downloadFile = async (baseUrl, path, filename = 'error.pdf') => {

 try {
  const axios = createAxios(baseUrl)
  const response = await axios.get(path, { responseType: 'blob'})

  if (!response) return false

  if(response.status === 200) {

    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    return true

  } else {
    return false
  }

 } catch (e) {
   return false
 }
}

