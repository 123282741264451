import React from 'react'
import { Spinner } from 'reactstrap'

export const PantallaCarga = () => {

    return(
        <div className='justify-content-center' style={{textAlign: "center"}}>
            <Spinner color="dark"/> Cargando...
        </div>
    )
}

export default PantallaCarga