import React, { useEffect, useState } from "react";
import { FaFilePdf, FaQuestion } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import {
	Bar, BarChart, CartesianGrid, Legend,
	ResponsiveContainer, Tooltip, XAxis,
	YAxis,Label
} from "recharts";
import { MensajesError } from "../../components/common/mensajesError/MensajesError";
import PantallaCarga from "../../components/common/pantallaCarga/PantallaCarga";
import SinResultados from "../../components/common/sinResultados/SinResultados";
import config from "../../config";
import formatoFecha from "../../utils/formatoFecha";
import formatAddress from "../../utils/addressFormatter";
import { getHistorialFacturaCliente } from "../../_services/axiosHistorialFacturaCliente";
import { get_query } from '../../_services/utils';
import { downloadFile } from "../utils/common/downloader";
import { checkNegocio, numberFormatter } from "../utils/common/formatters";
import { handleFecha } from "../utils/handlers";
import {
	checkEstadoContrato, handleDireccionCompleta,
	handleDireccionCompletaContrato, handleErrorState, query_listado_contratos, query_listado_facturas
} from "../utils/utils";
import numeroAprecio from "./../../utils/numeroAPrecio";
import "./Dashboard.scss";
import { FilaPaginacion } from "../../components/common/FilaPaginacion/FilaPaginacion";
import AlertaError from "../../components/AlertaError/AlertaError";


const getDireccion = async (factura) => {
	const cups = factura?.albaranes_albarans?.[0]?.producto;
	let variables = { cups: cups };
	const newBusqueda = {
	  query: `
		query ($cups: String!) {
		  comercial_producto(where: {codigo: {_eq: $cups}}) {
			accounts_userdireccion {
			  tipo_via
			  nombre_via
			  numero_via
			  portal
			  escalera
			  bloque
			  piso
			  puerta
			  poblacion
			  localidad
			  provincia
			  cod_postal
			  aclarador_finca
			}
		  }
		}
		`,
	  variables: variables,
	};
  
	const response = await get_query(newBusqueda).catch((error) => {
	  console.log(error);
	});

	let direccion;

	// No siempre recibe dirección
	if (response?.data?.data?.comercial_producto?.length == 0) {

		// Si no hay dirección metemos el cups
		direccion = "";

	} else {
		direccion = handleDireccionCompleta(response?.data?.data?.comercial_producto?.[0]?.accounts_userdireccion);
	}

	return direccion;
  };

const retrieveListadoFacturas = async (
	dispatch,
	setFacturas,
	facturas,
	busqueda,
	checkers,
	setCheckers,
	setCargandoFacturas,
	setHistorialFacturas,
	setCargandoHistorial
) => {
	
	if (busqueda.nif) {
		const variables = { "nif": busqueda.nif };
		setCargandoFacturas(true)
		setCargandoHistorial(true)
		const newBusqueda = {
		  query: query_listado_facturas,
		  variables: variables
		}
		const response = await get_query(newBusqueda).catch(error => {
		  setCargandoFacturas(false)
		  handleErrorState(setCheckers, checkers, 'errorListado')
		})
		setCargandoFacturas(false)
		const newFacturas = {
			results: response?.data?.data?.facturacion_facturas_emitidas_facturaemitida
		}

		// * Esto se encarga de pintar las direcciones en el listado de facturas
		if (newFacturas?.results) newFacturas.results.map((f) => {
			const direccion = f.albaranes_albarans?.producto_object?.accounts_userdireccion;
			f.direccion_completa = formatAddress(direccion);
		});

		setFacturas({ ...facturas, ...newFacturas })

		getHistorialFacturaCliente(busqueda)
		.then((response) => {
			setCargandoHistorial(false);
			setHistorialFacturas(response?.data);
		})
		.catch((error) => {
			setCargandoHistorial(false);
			handleErrorState(setCheckers, checkers, "errorHistorial");
		});
		 
	}
	
};


const retrieveListadoContratos = (
	setContratos,
	contratos,
	busqueda,
	checkers,
	setCheckers,
	setCargandoContratos
) => {
	let variables = { "nif": busqueda.nif };

	setCargandoContratos(true);
	const newBusqueda = {
		query: query_listado_contratos,
    	variables: variables
	};
	get_query(newBusqueda).then(response => {
			setCargandoContratos(false);
			let newContratos = {
				results: response.data.data.comercial_contrato
			  }
			  setContratos({ ...contratos, ...newContratos })
		  
		})
		.catch((error) => {
			setCargandoContratos(false);
			if (error) handleErrorState(setCheckers, checkers, "errorListado");
		});
};

const accionesContrato = (documento, filename, setMostrarAlerta) => {
	const path = `/api/v1/documentos/${documento ? documento : ""}`;
  
    return (
      <a
        onClick={ async () => {
          let resultado = await downloadFile(
            config.apiConfig.URL,
            path,
            filename
          )
  
          if (!resultado) setMostrarAlerta(true)
        }}
      >
        <Button disabled={!documento} size="sm" color={"danger"}>
          <FaFilePdf />
        </Button>
      </a>
    );

};

const acciones = (documento, filename, setMostrarAlerta) => {
	const path = `/api/v1/documentos/${documento ? documento : ""}`;
  
    return (
      <a
        onClick={ async () => {
          let resultado = await downloadFile(
            config.apiConfig.URLFACTURACION,
            path,
            filename
          )
  
          if (!resultado) setMostrarAlerta(true)
        }}
      >
        <Button disabled={!documento} size="sm" color={"danger"}>
          <FaFilePdf />
        </Button>
      </a>
    );
};

const compararFechas = (a, b) => {
	if (a.fecha < b.fecha) {
		return -1;
	}
	if (a.fecha > b.fecha) {
		return 1;
	}
	// a debe ser igual b
	return 0;
};

const compararInts = (a, b) => {
	if (a < b) {
		return -1;
	}
	if (a > b) {
		return 1;
	}
	// a debe ser igual b
	return 0;
};

const formatMonthsChart = (data = []) => {
	for (let i = 0; i < data.length; i++) {
		data[i].mes = data[i].mes;
	}

	return data;
};


const CustomTooltip = ({ active, payload, label }) => {


	let totalElectricidad = 0;
	let totalGas = 0;

	if (payload) {
		payload.forEach( _dato => {
			if (_dato?.dataKey == "total_electricidad") totalElectricidad = numeroAprecio(_dato?.value);
			if (_dato?.dataKey == "total_gas") totalGas = numeroAprecio(_dato?.value);
		})
	}

	return (
		<div style={{display: 'flex', backgroundColor: '#F8F8FF', flexDirection: 'column', padding: 15, }}>

			<div style={{flex: 1}}>
				<span style={{fontWeight: 'bold'}}>
					{label}
				</span>
			</div>

			<div style={{flex: 1,}}>
				<span style={{color: "rgb(247, 1, 0)", fontWeight: 'bold'}}>
					Importe electricidad:{" "}
				</span>
				<span style={{color: "rgb(102, 102, 102)", }}>
					{totalElectricidad}
				</span>
			</div>

			<div style={{flex: 1,}}>
				<span style={{color: "rgb(255, 107, 0)", fontWeight: 'bold'}}>
					Importe gas:{" "}
				</span>
				<span style={{color: "rgb(102, 102, 102)", }}>
					{totalGas}
				</span>
			</div>
			
		</div>
	)
}

const createBarChart = (listado = []) => {
	// const producto = factura.albaranes_albarans[0].producto
	return (
		<ResponsiveContainer width="95%" height={250}>
			<BarChart
				data={listado}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="mes" />
				<YAxis
					unit="€"
				/>
				<Tooltip
					content={<CustomTooltip />}
				/>
				<Legend />

				{listado.length > 0 ? (
					<Bar
						label="hola"
						name="Electricidad"
						dataKey="total_electricidad"
						fill="#f70100"
					></Bar>
				) : null}

				{listado.length > 0 ? (
					<Bar 
						name="Gas" 
						dataKey="total_gas" 
						fill="#ff6b00"
					></Bar>
				) : null}

			</BarChart>
		</ResponsiveContainer>
	);
};

const createTableContratos = (listado = [], setMostrarAlerta) => {
	const tabla = [];
	for (let i = 0; i < listado.length; i++) {
		const contrato = listado[i];
		tabla.push(
			<tr key={i}>
				<th scope="row">
					{contrato.producto  ? (
						checkNegocio(
							contrato.producto.negocio,
							contrato.modelo_contrato
						)
					) : (
						<FaQuestion />
					)}
				</th>
				<td className="d-none d-sm-table-cell">{contrato.num_contrato}</td>
				<td className="d-none d-sm-table-cell">
					{checkEstadoContrato(contrato.estado)}
				</td>
				<td className="d-none d-sm-table-cell">
					{contrato.producto ? contrato.producto.CUPS : null}
				</td>
				<td>{handleDireccionCompletaContrato(contrato)}</td>
				<td className="d-none d-sm-table-cell">{formatoFecha(contrato.fecha_inicio)}</td>
				<td className="d-none d-sm-table-cell">{formatoFecha(contrato.fecha_fin)}</td>
				<td>
					{accionesContrato(contrato.contrato_archivo_id, `${contrato.num_contrato}.pdf`, setMostrarAlerta)}
				</td>
			</tr>
		);
	}
	return tabla;
};

const createTableFacturas = (listado = [], setMostrarAlerta) => {

	const elementosTabla = [];

	for (let i = 0; i < 5; i += 1) {
		if (!listado[i]) continue;

		const factura = listado[i];
		elementosTabla.push(
			<tr key={i}>
				<th scope="row">
					{factura.negocio  ? (
						checkNegocio(
							factura.negocio == "ELE" ? 1 : 2
						)
					) : (
						<FaQuestion />
					)}
				</th>
				<td className='d-none d-sm-table-cell'>{factura.num_factura}</td>

        		<td className='d-none d-sm-table-cell'>
					{factura?.direccion_completa}
				</td>

				<td>{handleFecha(factura.fecha_factura)}</td>
				<td>{numberFormatter(factura.total_factura)}€</td>
				<td>{acciones(factura.documento_id, `${factura.num_factura}.pdf`, setMostrarAlerta)}</td>
			</tr>
		);
	}

	return elementosTabla;
};

export const Dashboard = () => {

	const [cargandoFacturas, setCargandoFacturas] = useState(false);
	const [cargandoContratos, setCargandoContratos] = useState(false);
	const cliente = useSelector((state) => state.root.cliente);
	const [facturas, setFacturas] = useState({ results: [] });
	const [historialFacturas, setHistorialFacturas] = useState([]);
	const [cargandoHistorial, setCargandoHistorial] = useState(false);
	const [contratos, setContratos] = useState({ results: [] });
	const [busquedaFactura, setBusquedaFactura] = useState({
		limit: 50,
		offset: 0,
		sort: "fecha_factura",
		order: "desc",
	});
	const [busquedaContrato, setBusquedaContrato] = useState({
		sort: "fecha_inicio",
		order: "desc",
	});
	const [checkers, setCheckers] = useState({ errorListado: 0 });
	
	const dispatch = useDispatch();

	const [limit, setLimit] = useState(5);
	const [desdeContrato, setDesdeContrato] = useState(0);
	const [hastaContrato, setHastaContrato] = useState(limit);
	const [mostrarAlerta, setMostrarAlerta] = useState(false);


	useEffect(() => {
		if (cliente.cliente) {
			setBusquedaFactura({ ...busquedaFactura, cliente: cliente.cliente.id, nif: cliente.nif })
			setBusquedaContrato({ ...busquedaContrato, cliente: cliente.cliente.id, nif: cliente.nif })
		  }
		}, [cliente])
	  

	useEffect(() => {
		if (cliente && cliente.id && cliente.cliente.id) {
			retrieveListadoFacturas(
				dispatch,
				setFacturas,
				facturas,
				// { ...busquedaFactura, cliente: cliente.cliente.id },
				busquedaFactura,
				checkers,
				setCheckers,
				setCargandoFacturas,
				setHistorialFacturas,
				setCargandoHistorial
			);
		}
	}, [busquedaFactura]);

	

	useEffect(() => {
		if (cliente && cliente.id) {
			retrieveListadoContratos(
				setContratos,
				contratos,
				busquedaContrato,
				checkers,
				setCheckers,
				setCargandoContratos
			);
		}
	}, [busquedaContrato]);



	useEffect(() => {
		cambiarPagina();
	  }, [limit]);

	
	const cambiarPagina = (botonSeleccionado) => {
		let desdeActual = desdeContrato;
		let hastaActual = hastaContrato;

		switch (botonSeleccionado) {
			case "<":
			if (desdeActual == 0) return;

			setDesdeContrato(desdeActual - limit);
			setHastaContrato(hastaActual - limit);
			break;

			case ">":
			if (desdeActual + limit > contratos?.results?.length) return

			setDesdeContrato(desdeActual + limit);
			setHastaContrato(desdeActual + limit + limit);
			break;

			default:
			setDesdeContrato(0);
			setHastaContrato(0 + limit);
		}
	};


	return (
		<div className="dashboard" style={{}}>

			<div className="title">
				<h4>Panel principal</h4>
			</div>

			<div className="mensajes-error">
				<MensajesError checkers={checkers} />
			</div>

			<div className="contenedorFacturasGrafico">

				<div className="seccion-facturas" style={{flex: 1}}>
					<div className="card">
						<div className="card-header">
							<h5>Tus últimas facturas</h5>
						</div>
						<div className="card-body">
							{cargandoFacturas ? (
								<PantallaCarga />
							) : !facturas.results || !facturas.results.length ? (
								<SinResultados />
							) : (
								// <TablaEditable 
								// 	titulos={nombresColumnasTablaUltimaFactura}
								
								// />
								<Table
									size="sm"
									responsive
									hover
									style={{ textAlign: "center" }}
									className="justify-content-center"
								>
									<thead>
										<tr>
											<th>Tipo </th>
											<th className="d-none d-sm-table-cell">Nº factura</th>
											
											{/* <th className="d-none d-sm-table-cell">Producto</th> */}
											<th className="d-none d-sm-table-cell">Dirección</th>
											<th>Fecha</th>
											<th>Total</th>
											<th>Acciones</th>
										</tr>
									</thead>
									<tbody>{createTableFacturas(facturas.results, setMostrarAlerta)}</tbody>
								</Table>
							)}
						</div>
					</div>
				</div>

				<div className="espacioEntreColumnas"></div>

				<div className="seccion-grafica" style={{flex: 1}}>
					<div className="card">
						<div className="card-header">
							<h5>Tu gráfico de gasto mensual</h5>
						</div>
						<div className="card-body">
							<div>
								{cargandoHistorial ? (
									<PantallaCarga />
								) : !historialFacturas || historialFacturas?.length == 0 ? (
									<SinResultados />
								) : (
									createBarChart(historialFacturas)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="seccion-contratos">
				<div className="card">
					<div className="card-header">
						<h5>Tus contratos activos</h5>
					</div>
					<div className="card-body">
						{cargandoContratos ? (
							<PantallaCarga />
						) : !contratos.results || !contratos.results.length ? (
							<SinResultados />
						) : (
							<>
							<Table
								responsive
								size="sm"
								hover
								style={{ textAlign: "center" }}
								className="justify-content-center"
							>
								<thead>
									<tr>
										<th>Tipo </th>
										<th className="d-none d-sm-table-cell">Contrato</th>
										<th className="d-none d-sm-table-cell">Estado</th>
										<th className="d-none d-sm-table-cell">Producto</th>
										<th className="d-none d-sm-table-cell">Dirección</th>
										<th className="d-none d-sm-table-cell">Inicio contrato</th>
										<th className="d-none d-sm-table-cell">Final contrato</th>
										<th className="d-none d-sm-table-cell">Acciones</th>
									</tr>
								</thead>
								<tbody>{createTableContratos(contratos.results?.slice(desdeContrato, hastaContrato, setMostrarAlerta))}</tbody>
							</Table>

							{contratos?.results?.length > 5 && 
								<FilaPaginacion 
									totalResultados={contratos?.results?.length}
									functionPulsaBoton={cambiarPagina}
									limiteDeElementos={limit}
									setLimiteDeElementos={setLimit}
									deshabilitadoBotonHaciaAtras={desdeContrato === 0}
									deshabilitadoBotonHaciaDelante={hastaContrato > contratos?.results?.length}
							  />
							}
							</>
						)}
					</div>
				</div>

				{ mostrarAlerta && <AlertaError mostrar={mostrarAlerta} setMostrar={setMostrarAlerta} /> }

				<div style={{height: '200px'}}></div>

			</div>
			
		</div>
	);
};
