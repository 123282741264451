import { combineReducers } from 'redux'
import contratosReducer from './contratos.reducer'
import facturasReducer from './facturas.reducer'
import rootReducer from './root.reducer'


export default combineReducers({
  facturas: facturasReducer,
  contratos: contratosReducer,
  root: rootReducer,
})