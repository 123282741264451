import React from 'react'
import DatePicker from 'react-datepicker'

import { Collapse } from 'reactstrap'

/** elementos, paginaActual, limit
 * Requeridos:
 * @param {Boolean} isOpen - indica si está abierto o cerrado el collapse
 * @param {Object} busqueda - Objeto que tiene los params de busqueda
 * @param {Object} data - Objeto que contiene los filtros a implementar
 * @param {Object} prop - Objeto que contiene los datos
 * @param dispatch - dispatcher de acciones
 * @param updateBusqueda - funcion que se usa para actualizar la busqueda
 * @param updateLista - funcion que se usa para actualizar la lista
 * @param funcionCargando - funcion que se usa para actualizar el estado de carga
 */

class FiltroComponente extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: false,
    }
  }

  toggle = () => {
    this.setState({
      toggle: !this.state.toggle
    })
  }

  changeDate = (value, name) => {
    const newValue = {
      ...this.props.busqueda,
      [name]: value,
      offset: 0,
    }

    this.props.updateBusqueda(this.props.dispatch, newValue, this.props.checkers)
    this.props.updateLista(this.props.dispatch, newValue, this.props.checkers, this.props.funcionCargando)
  }

  createFechas = (element) => {
    return (
      <>
        <div className='col-md-2 my-2'>
          <label className="my-2 mx-1">{element.description1 || "Fecha inicio"}</label>
          <DatePicker className="form-control"
            id={element.name1} name={element.name1}
            onChange={(e) => this.changeDate(e, element.name1)}
            selected={this.props.busqueda[element.name1]}
            locale="es-es"
            isClearable
            closeOnScroll={true}
            todayButton="Hoy"
            dateFormat="dd/MM/y"
          />
        </div>
        <div className='col-md-2 my-2'>
          <label className="my-2 mx-1">{element.description2 || "Fecha fin"}</label>
          <DatePicker className="form-control"
            id={element.name2} name={element.name2}
            onChange={(e) => this.changeDate(e, element.name2)}
            selected={this.props.busqueda[element.name2]}
            locale="es-es"
            isClearable
            closeOnScroll={true}
            todayButton="Hoy"
            dateFormat="dd/MM/y"
          />
        </div>
      </>
    )
  }

  changeSelect(name) {
    const value = document.getElementById(`${name}`).value
    const newValue = {
      ...this.props.busqueda,
      [name]: value === '0' ? null : value,
      offset: 0
    };

    this.props.updateBusqueda(this.props.dispatch, newValue)
    this.props.updateLista(this.props.dispatch, newValue, this.props.checkers, this.props.funcionCargando)
  }

  createSelect = (element) => {
    return (
      <div className='col-md-2 my-2'>
        <label className="my-2 mx-2">{element.description}</label>
        <select className='form-control' id={element.name}
          value={this.props.busqueda[element.name]}
          onChange={() => this.changeSelect(element.name)}>
          <option value='0'>Seleccionar...</option>
          {element.options.map(option =>
            <option value={option.value}>{option.name}</option>)}
        </select>
      </div>
    )
  }

  createElement = (element) => {
    switch (element.type) {
      case 'select':
        return this.createSelect(element)
      case 'fecha':
        return this.createFechas(element)
      default:
        return <></>
    }
  }

  ordenarFiltros = (data) => {
    const nombres = data.map(elemento => {
      if(elemento.type !== 'fecha') return elemento.description

      if(elemento.description1) return elemento.description1

      return 'Fecha inicio'
    }).sort()
    const filtrosOrdenados = []
    for (let i = 0; i < nombres.length; i++) {
      const nombre = nombres[i];
      let filtro = data.find(filtro => filtro.description === nombre);
      if(!filtro){
        if(nombre === 'Fecha inicio'){
          filtro = data.find(filtro => filtro.type === "fecha" && !filtro.description1)
        } else {
          filtro = data.find(filtro => filtro.description1 === nombre)
        }
      }
      filtrosOrdenados.push(filtro)
    }

    return filtrosOrdenados;
  }

  createFiltros = (data) => {
    const filtro = []
    const orderedData = this.ordenarFiltros(data)
    for (let i = 0; i < orderedData.length; i += 1) {
      const element = orderedData[i];
      filtro.push(this.createElement(element))
    }

    return filtro
  }

  render() {
    return (
        <Collapse className='col-md-12 row' isOpen={this.props.isOpen}>
          {this.createFiltros(this.props.data)}
        </Collapse>
    )
  }
}

export default (FiltroComponente)