import React from 'react'
import moment from 'moment'
import { 
  FaTimes,
  FaAngleUp,
  FaAngleDown,
  FaSort,
} from 'react-icons/fa'
import formatoFecha from '../../utils/formatoFecha'

export const handleFecha = (fecha) => {
  if (!fecha) {
    return (
      <FaTimes style={{ color: 'red' }} />
    );
  }

  return formatoFecha(fecha)
}

export const handleSorts = (busqueda, name) => {
  if (busqueda.sort === name) {
    if (busqueda.order === 'desc') {
      return (
        <div className='d-none d-sm-table-cell'>
          <FaAngleDown />
        </div>
      );
    }

    if (busqueda.order === 'asc') {
      return (
        <div className='d-none d-sm-table-cell'>
          <FaAngleUp />
        </div>
      );
    }
  } else {
    return (
      < div className='d-none d-sm-table-cell'>
        <FaSort />
      </div>
    );
  }

  return null;
}

export const sortBy = (busqueda, name, dispatch, updater) => {
  let orderDesc = true;
  if (name === busqueda.sort) {
    if (busqueda.order && busqueda.order.length) {
      orderDesc = busqueda.order === 'asc';
    }
  }
  const newBusqueda = {
    ...busqueda,
    sort: name,
    order: orderDesc ? 'desc' : 'asc',
  };

  dispatch(updater(newBusqueda));
}

export const propsUpdater = (busqueda, name, value, dispatch, updater) => {
  
  let newBusqueda = {
    nif: busqueda?.nif,
    limit: parseInt(value),
    offset: 0,
    order: "desc",
    paginaActual: busqueda?.paginaActual,
    sort: "fecha_factura"
  }

  dispatch(updater(newBusqueda))
}