import { UPDATE_LOGIN_ROOT, UPDATE_CLIENTE_ROOT} from "../constants/root.action-types";



export function updateCliente(cliente) {
  return { type: UPDATE_CLIENTE_ROOT, cliente }
};

export function updateLogin(login) {
  return { type: UPDATE_LOGIN_ROOT, login}
}